import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import HomeWorkSvg from "../../../Components/Svg/homeWorkSvg";

import {
	FILE_KEY,
	IMAGE_KEY,
	VIDEO_KEY,
	QUIZ_KEY,
	AUDIO_KEY,
} from "../../../Constants/mainKeys";
import audioSvg from "./../../../assets/icons/audio.svg";
import AssignmentsSvg from "../../../Components/Svg/AssignmentsSvg";

const ResourceOrActivityModal = props => {
	const locationHash = props.location.hash.substring(1);
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<ModalComponent
			title={translationService.translate("TR_ADD_RESOURCE_OR_ACTIVITY")}
			contentClassName="resource-modal"
			onSubmit={props.added}
			actionButtonTitle={translationService.translate("TR_ADD")}
			cancel={props.closeModal}
			isLarge={true}>
			<div className="d-flex resource-modal">
				<div className="light-gray-background">
					<ul
						className="nav nav-tabs light-gray-background modal-window-menu"
						role="tablist">
						<li className="w-100">
							<Link
								to={`#${IMAGE_KEY}`}
								className={`${
									locationHash === IMAGE_KEY ? "active" : ""
								} nav-link d-flex align-items-center`}>
								<i className="far fa-image"></i>
								{translationService.translate("TR_IMAGE")}
							</Link>
						</li>
						<li className="w-100">
							<Link
								to={`#${AUDIO_KEY}`}
								className={`${
									locationHash === AUDIO_KEY ? "active" : ""
								} nav-link d-flex align-items-center`}>
								{/* <i className="fas fa-audio"></i> */}
								<div
									style={{
										backgroundImage: `url(${audioSvg})`,
										width: "30px",
										marginRight: "15px",
										height: "30px",
									}}
								/>
								{translationService.translate("TR_AUDIO")}
							</Link>
						</li>
						<li className="w-100">
							<Link
								to={`#${VIDEO_KEY}`}
								className={`${
									locationHash === VIDEO_KEY ? "active" : ""
								} nav-link d-flex align-items-center`}>
								<i className="fas fa-film"></i>
								{translationService.translate("TR_VIDEO")}
							</Link>
						</li>
						<li className="w-100">
							<Link
								to={`#${FILE_KEY}`}
								className={`${
									locationHash === FILE_KEY ? "active" : ""
								} nav-link d-flex align-items-center`}>
								<i className="far fa-file-alt"></i>
								{translationService.translate("TR_FILE")}
							</Link>
						</li>
						<li className="w-100">
							<Link
								to={`#${QUIZ_KEY}`}
								className={`${
									locationHash === QUIZ_KEY ? "active" : ""
								} nav-link d-flex align-items-center`}>
								<i className="far fa-file-excel"></i>
								{translationService.translate("TR_QUIZ")}
							</Link>
						</li>
						<li className="w-100">
							<Link
								to={`#assignment`}
								className={`${
									locationHash === "assignment" ? "active" : ""
								} nav-link d-flex align-items-center`}>
								{/* <HomeWorkSvg /> */}
								<AssignmentsSvg />
								{/* <i className="fa-solid fa-chalkboard-user"></i> */}
								<span className="mb-2">
									{translationService.translate("TR_ASSIGNMENTS")}
								</span>
							</Link>
						</li>
					</ul>
				</div>
				<div className="tab-content scroll" role="tablist">
					<div
						id="pane-image"
						className="card tab-pane fade show active h-100 border-0"
						role="tabpanel"
						aria-labelledby="tab-image">
						<div
							id="collapse-image"
							className="collapse show"
							role="tabpanel"
							aria-labelledby="heading-image">
							<div className="card-body">
								{props.history.location.hash === "#image"
									? translationService.translate("TR_ADD_RESOURCE_IMAGE_INFO")
									: props.history.location.hash === "#video"
									? translationService.translate("TR_ADD_RESOURCE_VIDEO_INFO")
									: props.history.location.hash === "#audio"
									? translationService.translate("TR_ADD_RESOURCE_AUDIO_INFO")
									: props.history.location.hash === "#file"
									? translationService.translate("TR_ADD_RESOURCE_FILE_INFO")
									: props.history.location.hash === "#quiz"
									? translationService.translate("TR_ADD_RESOURCE_QUIZ_INFO")
									: props.history.location.hash === "#assignment"
									? translationService.translate(
											"TR_ASSIGNMENT_ADD_RESOURCE_OR_QUIZ",
									  )
									: translationService.translate("TR_ADD_RESOURCE_INFO")}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ModalComponent>
	) : null;
};

export default withRouter(ResourceOrActivityModal);

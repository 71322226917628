import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import ApiService from "../../Services/apiService";
import Input from "../../Components/Inputs/input";
import TextArea from "../../Components/Inputs/textArea";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import {
	ERROR_KEY,
	IMAGE_KEY,
	NUMBER_KEY,
	STRING_KEY,
	SUCCESS_KEY,
	VIDEO_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import { COURSE_DASHBOARD_KEY, CURICULUMN_KEY } from "../../Constants/urlKeys";
import { ORGANIZATION_KEY } from "../../Constants/requestKeys";
import AddFileLibraryModal from "../CourseTopics/Modals/addFileLibraryModal";
import AddFromLibraryModal from "../CourseTopics/Modals/addFromLibraryModal";
import CloseSvg from "./../../Components/Svg/closeSvg";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import ActionButton from "../../Components/ActionButton/actionButton";
import file from "./../../assets/icons/file.png";
import RichTextEditor from "../Questions/Components/RichTextEditor";

const valuesMaxLength = {
	title: 200,
};

const spinnerId = uuid();

const AssignmentForm = props => {
	const dispatch = useDispatch();

	const { courseId, topicId, assignmentId } = props.match.params;
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [fileType, setFileType] = useState("");
	const [showSelectFromFileLibraryModal, setShowSelectFromFileLibraryModal] =
		useState(false);
	const [showAddNewFileLibraryModal, setShowAddNewFileLibraryModal] =
		useState(false);
	const [name, setName] = useState("");
	const [selectedFiles, setSelectedFiles] = useState([]);

	const [values, setValues] = useState({
		entityId: topicId ? +topicId : +courseId,
		entityType: topicId ? 1 : 0,
		title: "",
		description: null,
		fileIds: [],
		requiresStudentResponse: false,
		enableResultFileRemoval: false,
		daysUntilFileRemoval: 0,
		grade: null,
		expirationDate: null,
	});
	const [zoom, setZoom] = useState({
		imagePath: null,
		audioPath: null,
		videoPath: null,
	});

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (assignmentId) getAssignmentByLecturerId(assignmentId);
		else setValues(values => ({ ...values, description: "" }));
	}, [assignmentId]);

	const onEditorChange = (event, fieldName) => {
		const newRichText = event.editor.getData();
		setValues(values => ({
			...values,
			[fieldName]: newRichText,
		}));
	};

	const onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setIsInvalidSubmit(false);
		setValues(values => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};

	// const onNumberChange = (event, fieldname, maxValue = Infinity) => {
	// 	if (event.target.value.includes("e") || event.target.value.includes(".")) {
	// 		return false;
	// 	}
	// 	if (maxValue && maxValue < event.target.value.length) {
	// 		return false;
	// 	}
	// 	setIsChange(true);
	// 	setIsInvalidSubmit(false);
	// 	if (event.target.value === "" || Number.isInteger(+event.target.value)) {
	// 		setValues(values => ({ ...values, [fieldname]: event.target.value }));
	// 	}
	// };

	const attachFileLibrary = files => {
		if (!fileType || !files) {
			return false;
		}
		const ids = [];
		setSelectedFiles(() => {
			if (Array.isArray(files)) {
				return [...selectedFiles, ...files];
			} else {
				return [...selectedFiles, files];
			}
		});
		closeModal(false);
	};

	const closeModal = () => {
		setShowAddNewFileLibraryModal(false);
		setShowSelectFromFileLibraryModal(false);
	};

	const onShowAddNewFileLibraryModal = () => {
		setShowAddNewFileLibraryModal(true);
	};

	const removeFile = element => {
		if (!element) {
			return false;
		}
		setSelectedFiles(selectedFiles.filter(item => item.id !== element.id));
	};

	const onCheckboxChange = event => {
		if (
			event.target.name === "enableResultFileRemoval" &&
			!event.target.checked
		) {
			setValues(values => ({
				...values,
				[event.target.name]: event.target.checked,
				period: 0,
			}));
			return;
		}
		setValues(values => ({
			...values,
			[event.target.name]: event.target.checked,
		}));
	};

	const getAssignmentByLecturerId = id => {
		if (!id) return;
		const pageSpinner = uuid();
		dispatch(addPageSpinner(pageSpinner));
		ApiService.getAssignmentByLecturerId(id)
			.then(response => {
				if (response && response.data) {
					const newValuse = { ...values };
					if (response.data.files && response.data.files.length) {
						setSelectedFiles(response.data.files);
					}

					Object.entries(values).forEach(([key, value]) => {
						if (key === "description") {
							newValuse[key] = response.data[key] || "";
						} else {
							newValuse[key] = response.data[key];
						}
					});
					newValuse.id = response.data.id;
					setValues(newValuse);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePageSpinner(pageSpinner));
			});
	};

	const onNumberChange = event => {
		var val = event.target.value;
		val = val.replace(/^0+|[^\d.]/g, "");
		if (event.target.value.includes("e")) {
			return false;
		}
		if (event.target.name === "grade" && +val === 0) {
			setValues(values => ({
				...values,
				[event.target.name]: "",
			}));
			return;
		}
		if (val === "" || (typeof +val === NUMBER_KEY && Number(val) >= 0)) {
			setValues(values => ({
				...values,
				[event.target.name]: +val,
			}));
		}
	};

	const onDateChange = (event, cb) => {
		setValues(values => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};

	const hideZoomImageVideo = () => {
		setZoom({
			imagePath: null,
			audioPath: null,
			videoPath: null,
		});
	};

	const showZoom = (type, path) => {
		if (!type || !path) return false;
		setZoom(zoom => ({
			...zoom,
			[type]: path,
		}));
	};

	const onSubmit = event => {
		event.preventDefault();
		if (!values.title.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		if (!values.grade) {
			values.grade = null;
		}
		dispatch(addButtonSpinner(spinnerId));
		const newValuses = {
			...values,
			fileIds: selectedFiles.map(el => el.id),
		};
		if (!topicId) {
			(!assignmentId
				? ApiService.createCourseAssignment(newValuses)
				: ApiService.updateCourseAssignment(newValuses)
			)
				.then(response => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					// props.history.push(
					// 	`/${language}/organization/$coursedashboard/${courseId}/curriculum`,
					// );
					window.history.back();
				})
				.catch(error => {
					error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
				})
				.finally(() => {
					dispatch(removeButtonSpinner(spinnerId));
				});
		} else {
			(!assignmentId
				? ApiService.createTopicAssignment(newValuses)
				: ApiService.updateTopicAssignment(newValuses)
			)
				.then(response => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					// props.history.push(
					// 	`${
					// 		user.isOrganizationUser
					// 			? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${courseId}/curriculum`
					// 			: `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${CURICULUMN_KEY}`
					// 	}`,
					// );
				})
				.catch(error => {
					error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
				})
				.finally(() => {
					dispatch(removeButtonSpinner(spinnerId));
				});
		}
	};

	return translationService ? (
		<div className="background content container">
			<div className="m-section">
				<h2 className="content-title p-0">
					<Link
						to={
							user.isOrganizationUser
								? `/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}/${courseId}/curriculum`
								: `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${CURICULUMN_KEY}`
						}
						title={translationService.translate("TR_BACK")}>
						<ArrowBackSvg />
					</Link>
					{assignmentId
						? translationService.translate("TR_UPDATE_ASSIGNMENT")
						: translationService.translate("TR_CREATE_ASSIGNMENT")}
				</h2>
				<hr />
				{zoom.imagePath ? (
					<div className="zoom-image-container" onClick={hideZoomImageVideo}>
						<div className="close-svg">
							<CloseSvg />
						</div>
						<img
							src={zoom.imagePath}
							alt="/"
							onClick={event => event.stopPropagation()}
						/>
					</div>
				) : null}
				{zoom.videoPath ? (
					<div className="zoom-image-container" onClick={hideZoomImageVideo}>
						<div className="zoom-image-block">
							<div className="close-svg">
								<CloseSvg />
							</div>
							<video controls={true} onClick={event => event.stopPropagation()}>
								<source src={zoom.videoPath} />
							</video>
						</div>
					</div>
				) : null}

				<div className="content-body p-0">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-12 ">
								<div className="content-sub-title">
									<h3>{translationService.translate("TR_GENERAL")}</h3>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-12 ">
										<div className="row">
											<div className="col-12 col-md-6">
												<Input
													type="text"
													id="title"
													name="title"
													inputClassName="pr--4"
													value={values.title}
													fieldLength={valuesMaxLength.title}
													isInvalidSubmit={isInvalidSubmit}
													labelValue={`${translationService.translate(
														"TR_NAME",
													)} *`}
													onChange={event =>
														onChange(event, valuesMaxLength.title)
													}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<Input
											type="number"
											id="title"
											name="grade"
											// inputClassName="pr--4"
											value={values.grade || ""}
											// fieldLength={valuesMaxLength.title}
											// isInvalidSubmit={isInvalidSubmit}
											labelValue={`${translationService.translate("TR_GRADE")}`}
											onChange={event => onNumberChange(event)}
										/>
									</div>
									<div className="col-md-6">
										<Input
											type="date"
											id="expirationDate"
											name="expirationDate"
											value={
												values.expirationDate
													? new Date(values.expirationDate)
															.toISOString()
															.split("T")[0]
													: ""
											}
											labelValue="Expiry date"
											min={`${new Date().toISOString().split("T")[0]}`}
											// labelValue={`${translationService.translate(
											// 	"TR_NAME",
											// )} *`}
											onChange={event => onDateChange(event)}
										/>
									</div>
									<div className="col-md-6">
										<label htmlFor="requiresStudentResponse">
											{translationService.translate(
												"TR_REQUIRES_STUDENT_RENSPONSE",
											)}
										</label>
										<InputCheckBox
											id="requiresStudentResponse"
											name="requiresStudentResponse"
											checked={values.requiresStudentResponse}
											labelValue={translationService.translate("TR_YES")}
											onChange={onCheckboxChange}
										/>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-12">
												<label htmlFor="deleteFilesDey">
													{translationService.translate(
														"TR_ENABLE_RESULT_FILE_REMOVAL",
													)}
												</label>
												<InputCheckBox
													id="deleteFilesDey"
													name="enableResultFileRemoval"
													checked={values.enableResultFileRemoval}
													labelValue={translationService.translate("TR_YES")}
													onChange={onCheckboxChange}
												/>
											</div>
											{values.enableResultFileRemoval && (
												<div className="col-12">
													<Input
														type="number"
														id="daysUntilFileRemoval"
														name="daysUntilFileRemoval"
														min="0"
														infoText={translationService.translate(
															"TR_DURATION_DAYS_INFO",
														)}
														value={values.daysUntilFileRemoval}
														labelValue={`${translationService.translate(
															"TR_DURATION_DAYS",
														)}*`}
														onChange={onNumberChange}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="col-12">
										{typeof values.description === STRING_KEY ? (
											<div className="my-3">
												<RichTextEditor
													value={values.description}
													config={{
														toolbar: [
															["Bold", "Italic", "Strike", "-", "RemoveFormat"],
															["Undo", "Redo"],
															[
																"NumberedList",
																"BulletedList",
																"-",
																"Outdent",
																"Indent",
																"-",
																"Blockquote",
															],
															["Styles", "Format"],
															// ['Maximize']
														],
													}}
													onEditorChange={event =>
														onEditorChange(event, "description")
													}
												/>
											</div>
										) : null}
									</div>
									<div className="col-12">
										<div className="row mt-3">
											<div className="col-lg-6 col-md-6 col-12">
												<button
													type="button"
													className={`mindalay--btn-secondary w-100 mb-2 ${
														fileType === "image"
															? "active-upload-button"
															: "border-2-transparent"
													}`}
													onClick={() => setFileType("image")}>
													{translationService.translate("TR_ATTACH_IMAGE")}
												</button>
											</div>
											<div className="col-lg-6 col-md-6 col-12">
												<button
													type="button"
													className={`mindalay--btn-secondary w-100 mb-2 ${
														fileType === "file"
															? "active-upload-button"
															: "border-2-transparent"
													}`}
													onClick={() => setFileType("file")}>
													{translationService.translate("TR_ATTACH_FILE")}
												</button>
											</div>
										</div>
										{fileType ? (
											<div
												className={`row ${
													fileType === "image"
														? "justify-content-start"
														: "justify-content-end"
												}`}>
												<div className="col-lg-6 col-md-6 col-12">
													<div>
														<button
															type="button"
															className={`mindalay--btn-default w-100 mb-2`}
															onClick={() =>
																setShowSelectFromFileLibraryModal(true)
															}>
															{translationService.translate(
																"TR_ADD_FROM_LIBRARY",
															)}
														</button>
														{showSelectFromFileLibraryModal ? (
															<AddFromLibraryModal
																fileType={fileType}
																isMultiple={true}
																cancel={closeModal}
																added={attachFileLibrary}
																isShowAddNewFileLibraryModal={
																	onShowAddNewFileLibraryModal
																}
															/>
														) : null}
													</div>
													<div>
														<button
															type="button"
															className={`mindalay--btn-default w-100 mb-2`}
															onClick={() =>
																setShowAddNewFileLibraryModal(true)
															}>
															{translationService.translate("TR_ADD_NEW_FILE")}
														</button>
														{showAddNewFileLibraryModal ? (
															<AddFileLibraryModal
																fileType={fileType}
																cancel={closeModal}
																isMultiple={true}
																added={attachFileLibrary}
																name={name}
															/>
														) : null}
													</div>
												</div>
											</div>
										) : null}
										<div className="row">
											{selectedFiles && selectedFiles.length
												? selectedFiles.map((item, index) => {
														return (
															<div
																key={index}
																className="col-lg-6 col-md-6 col-sm-6 col-12">
																<div className="course-media-wrapper">
																	{(() => {
																		switch (true) {
																			case item.mimeType &&
																				item.mimeType.includes(IMAGE_KEY):
																				return (
																					<div className="upload-file-content">
																						<div
																							onClick={() => removeFile(item)}>
																							<CloseSvg />
																						</div>
																						<div
																							className="img cursor-pointer"
																							onClick={() => {
																								showZoom(
																									"imagePath",
																									item.filePath,
																								);
																							}}
																							style={{
																								backgroundImage: `url(${item.filePath})`,
																							}}
																							alt="course-image"
																						/>
																					</div>
																				);
																			case item.mimeType &&
																				item.mimeType.includes(VIDEO_KEY):
																				return (
																					<div className="course-media-wrapper">
																						<div className="upload-file-content">
																							<div
																								onClick={() =>
																									removeFile(item)
																								}>
																								<CloseSvg controls={true} />
																							</div>
																							<video
																								id="video"
																								src={item.filePath}
																								alt="course-image"
																								controls
																							/>
																						</div>
																					</div>
																				);

																			case item.mimeType &&
																				(item.mimeType.includes("pdf") || //pdf
																					item.mimeType.includes("text") || //txt
																					item.mimeType.includes(
																						"application",
																					)):
																				return (
																					<div className="upload-file-content d-flex justify-content-center align-items-center img">
																						<div
																							onClick={() => removeFile(item)}>
																							<CloseSvg />
																						</div>
																						<img
																							src={file}
																							style={{ width: "100px" }}
																						/>
																					</div>
																				);
																			default:
																				break;
																		}
																	})()}
																</div>
															</div>
														);
												  })
												: null}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <SubmitButton
						id={assignmentId}
						spinnerId={spinnerId}
						className="mt-3"
					/> */}
						<hr />
						<ActionButton
							type="submit"
							spinnerId={spinnerId}
							className="mindalay--btn-default position-relative"
							name={
								!assignmentId
									? translationService.translate("TR_SAVE")
									: translationService.translate("TR_UPDATE")
							}
						/>
					</form>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(AssignmentForm);

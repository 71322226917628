// import React from "react";

// function AssignmentsSvg(props) {
// 	return (
// 		<svg
// 			xmlns="http://www.w3.org/2000/svg"
// 			xmlnsXlink="http://www.w3.org/1999/xlink"
// 			version="1.1"
// 			x="0px"
// 			y="0px"
// 			viewBox="0 0 32 40"
// 			style={{ enableBackground: "new 0 0 32 32" }}
// 			xmlSpace="preserve">
// 			<g>
// 				<path d="M18.396,11.326H8.481c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h9.916c0.497,0,0.9-0.403,0.9-0.9   S18.894,11.326,18.396,11.326z" />
// 				<path d="M19.296,16c0-0.497-0.403-0.9-0.9-0.9H8.481c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h9.916   C18.894,16.9,19.296,16.497,19.296,16z" />
// 				<path d="M8.481,18.873c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h4.958c0.497,0,0.9-0.403,0.9-0.9s-0.403-0.9-0.9-0.9H8.481z" />
// 				<path d="M14.33,27.119H5.19c-0.606,0-1.1-0.493-1.1-1.1v-2.178h0.297c0.497,0,0.9-0.403,0.9-0.9s-0.403-0.9-0.9-0.9H4.09v-2.827   h0.297c0.497,0,0.9-0.403,0.9-0.9s-0.403-0.9-0.9-0.9H4.09v-2.826h0.297c0.497,0,0.9-0.403,0.9-0.9s-0.403-0.9-0.9-0.9H4.09V9.959   h0.297c0.497,0,0.9-0.403,0.9-0.9s-0.403-0.9-0.9-0.9H4.09V5.98c0-0.606,0.494-1.1,1.1-1.1h15.3c0.606,0,1.1,0.493,1.1,1.1v6.279   c0,0.497,0.403,0.9,0.9,0.9s0.9-0.403,0.9-0.9V5.98c0-1.599-1.301-2.9-2.9-2.9H5.19c-1.599,0-2.9,1.302-2.9,2.9v2.178H2   c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h0.29v2.827H2c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h0.29v2.826H2   c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h0.29v2.827H2c-0.497,0-0.9,0.403-0.9,0.9s0.403,0.9,0.9,0.9h0.29v2.178   c0,1.599,1.301,2.9,2.9,2.9h9.14c0.497,0,0.9-0.403,0.9-0.9S14.827,27.119,14.33,27.119z" />
// 				<path d="M30,12.781c-1.199-1.198-3.148-1.197-4.346,0l-8.879,8.879c-0.248,0.247-0.41,0.561-0.468,0.906l-0.402,2.379   c-0.09,0.533,0.084,1.08,0.466,1.463c0.316,0.316,0.746,0.491,1.188,0.491c0.092,0,0.185-0.008,0.276-0.022l2.377-0.402   c0.343-0.057,0.656-0.218,0.908-0.467L30,17.128c0.581-0.581,0.9-1.353,0.9-2.173C30.9,14.134,30.581,13.362,30,12.781z    M19.915,24.699l-2.208,0.374l0.339-2.139l6.751-6.751l1.804,1.804L19.915,24.699z M28.727,15.854l-0.855,0.858l-1.802-1.802   l0.856-0.856c0.249-0.248,0.574-0.373,0.9-0.373s0.652,0.125,0.9,0.373c0.241,0.24,0.373,0.56,0.373,0.9   C29.1,15.295,28.968,15.614,28.727,15.854z" />
// 			</g>
// 		</svg>
// 	);
// }

// export default AssignmentsSvg;

import React from "react";

function AssignmentsSvg(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 64 80"
			enableBackground="new 0 0 64 64"
			xmlSpace="preserve">
			<g>
				<g>
					<g>
						<path d="M49.326,0H7.601C6.25,0,5.155,1.095,5.155,2.446v9.993H2.182c-1.042,0-1.887,0.845-1.887,1.887     c0,1.042,0.845,1.887,1.887,1.887h2.973v5.064H2.182c-1.042,0-1.887,0.845-1.887,1.887c0,1.042,0.845,1.887,1.887,1.887h2.973     v5.064H2.182c-1.042,0-1.887,0.845-1.887,1.887s0.845,1.887,1.887,1.887h2.973v5.064H2.182c-1.042,0-1.887,0.845-1.887,1.887     s0.845,1.887,1.887,1.887h2.973v5.064H2.182c-1.042,0-1.887,0.845-1.887,1.887c0,1.042,0.845,1.887,1.887,1.887h2.973v9.993     C5.155,62.905,6.25,64,7.601,64h41.725c1.351,0,2.446-1.095,2.446-2.446V2.446C51.772,1.095,50.677,0,49.326,0z M48.327,60.555     H8.599v-8.994h3.01c1.042,0,1.887-0.845,1.887-1.887c0-1.042-0.845-1.887-1.887-1.887h-3.01v-5.064h3.01     c1.042,0,1.887-0.845,1.887-1.887s-0.845-1.887-1.887-1.887h-3.01v-5.064h3.01c1.042,0,1.887-0.845,1.887-1.887     s-0.845-1.887-1.887-1.887h-3.01v-5.064h3.01c1.042,0,1.887-0.845,1.887-1.887c0-1.042-0.845-1.887-1.887-1.887h-3.01v-5.064     h3.01c1.042,0,1.887-0.845,1.887-1.887c0-1.042-0.845-1.887-1.887-1.887h-3.01V3.445h39.728V60.555z" />
						<path d="M15.517,12.88h10.894c0.381,0,0.689-0.308,0.689-0.689s-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689S15.136,12.88,15.517,12.88z" />
						<path d="M15.517,18.539h10.894c0.381,0,0.689-0.308,0.689-0.689s-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689S15.136,18.539,15.517,18.539z" />
						<path d="M15.517,24.199h10.894c0.381,0,0.689-0.308,0.689-0.689c0-0.38-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689C14.828,23.891,15.136,24.199,15.517,24.199z" />
						<path d="M15.517,29.859h25.894c0.381,0,0.689-0.308,0.689-0.689c0-0.38-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689C14.828,29.551,15.136,29.859,15.517,29.859z" />
						<path d="M15.517,35.519h25.894c0.381,0,0.689-0.308,0.689-0.689c0-0.38-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689C14.828,35.21,15.136,35.519,15.517,35.519z" />
						<path d="M15.517,41.179h25.894c0.381,0,0.689-0.308,0.689-0.689c0-0.38-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689C14.828,40.87,15.136,41.179,15.517,41.179z" />
						<path d="M15.517,46.839h25.894c0.381,0,0.689-0.308,0.689-0.689s-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689S15.136,46.839,15.517,46.839z" />
						<path d="M15.517,52.498h25.894c0.381,0,0.689-0.308,0.689-0.689c0-0.38-0.308-0.689-0.689-0.689H15.517     c-0.381,0-0.689,0.308-0.689,0.689C14.828,52.19,15.136,52.498,15.517,52.498z" />
						<path d="M41.1,24.199H29.815c-0.552,0-1-0.448-1-1V12.502c0-0.552,0.448-1,1-1H41.1c0.552,0,1,0.448,1,1v10.698     C42.1,23.752,41.652,24.199,41.1,24.199z" />
					</g>
					<path d="M63.699,22.441c-0.002-0.046-0.007-0.092-0.016-0.139c-0.006-0.028-0.013-0.055-0.021-0.082    c-0.006-0.02-0.008-0.039-0.015-0.059l-4.181-11.582c-0.138-0.383-0.501-0.638-0.908-0.638c-0.407,0-0.77,0.255-0.908,0.638    l-4.182,11.582c-0.007,0.019-0.009,0.039-0.015,0.059c-0.008,0.027-0.015,0.054-0.021,0.082c-0.009,0.046-0.014,0.092-0.016,0.139    c-0.001,0.016-0.006,0.032-0.006,0.048l0,21.693l0,7.718c0,0.335,0.174,0.647,0.459,0.822c1.446,0.89,3.067,1.335,4.688,1.335    c1.621,0,3.242-0.445,4.688-1.335c0.285-0.176,0.459-0.487,0.459-0.822l0-7.718l0-21.693C63.705,22.473,63.7,22.457,63.699,22.441    z M59.622,16.698l1.852,5.129c-1.924,0.436-3.908,0.436-5.832,0l1.852-5.129H59.622z M55.342,23.74    c0.32,0.07,0.641,0.125,0.963,0.174l0,16.239c-0.328-0.113-0.649-0.251-0.963-0.414L55.342,23.74z M56.949,24.001    c0.428,0.046,0.858,0.069,1.288,0.078l0,16.445c-0.433-0.02-0.863-0.08-1.288-0.181L56.949,24.001z M58.88,24.078    c0.43-0.009,0.859-0.032,1.288-0.078l0,16.341c-0.425,0.101-0.855,0.161-1.288,0.181L58.88,24.078z M60.811,23.914    c0.322-0.049,0.643-0.104,0.963-0.174l0,15.999c-0.314,0.163-0.634,0.301-0.963,0.414L60.811,23.914z M55.342,41.869    c1.037,0.403,2.126,0.609,3.216,0.609c1.09,0,2.179-0.206,3.216-0.609v1.752c-2.025,1.05-4.408,1.049-6.432,0L55.342,41.869z     M55.342,51.338l0-5.607c1.037,0.403,2.126,0.609,3.216,0.609c1.09,0,2.179-0.206,3.216-0.609l0,5.607    C59.749,52.388,57.366,52.388,55.342,51.338z" />
				</g>
			</g>
		</svg>
	);
}

export default AssignmentsSvg;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import Input from "./../Components/Inputs/input";
import ReactSelectOption from "./../Components/SelectOption/reactSelectOption";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
} from "../Constants/mainKeys";
import { DOCUMENT_KEY } from "../Constants/urlKeys";
import AlertService from "../Services/alertService";
import ApiService from "../Services/apiService";
import TranslationService from "../Services/translationService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePageSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ArrowBackSvg from "./../Components/Svg/arrowBackSvg";
import CloseSvg from "./../Components/Svg/closeSvg";
import UploadButton from "./../Components/UploadButton/uploadButton";
import MainService from "./../Services/mainService";
import Textarea from "./../Components/Inputs/textArea";
import ActionButton from "./../Components/ActionButton/actionButton";
import "./../documentsForm.css";

const buttonSpinnerId = uuid();

const DocumentForm = props => {
	const containerRef = useRef(null);
	const [documentType, setDocumentType] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState([]);

	const [templateInfo, setTemplateInfo] = useState(null);
	const [templateData, setTemplateData] = useState("");
	const { documentId } = props.match.params;
	const nameMaxLength = 120;
	const descriptionMaxLength = 250;
	const mainService = new MainService();
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const [documentTypeId, setDocumentTypeId] = useState(null);
	const [documentTemplateId, setDocumentTemplateId] = useState(null);
	const [documentCategoryId, setDocumentCategoryId] = useState(2);
	const [translationService, setTranslationService] = useState(null);
	const [documentName, setDocumentName] = useState("");
	const [documentBodyData, setDocumentBodyData] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);
	const [description, setDescription] = useState("");
	const [tableRowCount, setTableRowCount] = useState(1);
	const [uploadedFilePath, setUploadedFilePath] = useState(null);
	const [tableTds, setTableTds] = useState([]);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [file, setFile] = useState(null);
	const [tableListDates, setTableListDates] = useState({});
	// const [groupName, setGroupName] = useState("");
	// const [organizationName, setOrganizationName] = useState("");
	const [tableLibDatas, setTableLibDatas] = useState({});
	const [groupData, setGroupData] = useState([]);
	const [inputsListData, setInputsListData] = useState([]);
	const [students, setStudents] = useState([]);
	const [studentsList, setStudentsList] = useState("");
	const [values, setValues] = useState({
		a1: "",
		a2: "",
		a3: "",
		a4: "",
		a5: "",
		a6: "",
		a7: "",
		a8: "",
		a9: "",
		a10: "",
		a11: "",
		a12: "",
		a13: "",
		a14: "",
		a15: "",
		a16: "",
		a17: "",
		a18: "",
		a19: "",
		a20: "",
		a21: "",
		a22: "",
		a23: "",
		a24: "",
		a25: "",
		a26: "",
		a27: "",
		a28: "",
		a29: "",
		a30: "",
		a31: "",
		a32: "",
		a33: "",
		tableData: [],
	});

	// useEffect(() => {
	// 	if (documentId) {
	// 		getDocumentById(documentId);
	// 	}
	// 	getOrgAndGroupname();
	// }, []);

	useEffect(() => {
		getDocumentCategory();
	}, []);

	useEffect(() => {
		if (documentId) {
			getDocumentById(documentId);
		} else {
		}
	}, [documentId]);

	useEffect(() => {
		const container = document.querySelector(".template-container");
		if (container) {
			container.addEventListener("click", buttonClick);
		}

		return () => {
			if (container) {
				container.removeEventListener("click", buttonClick);
			}
		};
	}, [templateData, tableTds, tableRowCount, groupData, containerRef]);

	useEffect(() => {
		if (containerRef) {
			setTimeout(() => {
				attachEventListeners();
			}, 1);
			return removeEventListeners();
		}
	}, [templateData.body, containerRef]);

	// useEffect(() => {
	// 	const container = document.querySelector(".template-container");
	// 	if (container) {
	// 		const inputs = container.querySelectorAll("input, select, textarea");
	// 		inputs.forEach(input => {
	// 			input.addEventListener("change", focusOut);
	// 		});

	// 		// Удаление обработчиков при размонтировании
	// 		return () => {
	// 			inputs.forEach(input => {
	// 				input.removeEventListener("change", focusOut);
	// 			});
	// 		};
	// 	}
	// }, [templateData.body]);

	const changeTemplateValue = event => {
		const fieldName = event.target.name;
		const newValue = event.target.value;

		setValues(values => ({
			...values,
			[fieldName]: newValue,
		}));

		const inputElement = containerRef.current.querySelector(
			`[name="${fieldName}"]`,
		);

		if (inputElement) {
			if (inputElement.className.includes("select-list")) {
				if (inputElement.getAttribute("data-type") === "isStudent") {
					const item = groupData.find(el => el.name === newValue);
					if (item && item.id) {
						getDivisionsStudents(item.id, containerRef.current);
					}
				}
				inputElement.setAttribute("value", newValue);
				inputElement.setAttribute("selected", newValue);
				[...inputElement.options].forEach(option => {
					option.setAttribute("selected", option.innerHTML === newValue);
				});
			} else {
				inputElement.setAttribute("value", newValue);
			}
			setTemplateData(prevData => ({
				...prevData,
				body: containerRef.current.innerHTML,
			}));
		}
	};

	const getDocumentById = id => {
		if (!id) return false;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentById(id)
			.then(response => {
				if (response && response.data) {
					const spinnerId = uuid();
					ApiService.getDocumentTemplatesByCategoryId(
						response.data.documentCategoryId,
					)
						.then(_response => {
							if (_response && _response.data) {
								setTemplates(_response.data);
								let templates = _response.data;

								setDocumentTypeId(response.data.documentCategoryId);
								ApiService.getDocumentTemplateInfoInTemplateId(
									response.data.documentTemplateId,
								)
									.then(res => {
										if (res && res.data) {
											setDocumentTemplateId(response.data.documentTemplateId);
											let selectedItem = _response.data.find(
												item => item.id === response.data.documentTemplateId,
											);
											setTemplateData(selectedItem);
											setTemplateInfo(res.data);
											setTemplateInfoData(
												res.data,
												selectedItem,
												response.data.data,
											);
										}
									})
									.catch(error =>
										AlertService.alert(
											AlertService.checkMessageType(error.respcode) ||
												ERROR_KEY,
											error,
										),
									)
									.finally(() => {
										// dispatch(removePartialViewSpinner(spinnerId));
									});

								// const documentData = JSON.parse(selectedItem.body);
							}
						})
						.catch(error =>
							AlertService.alert(
								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
								error,
							),
						)
						.finally(() => {
							// dispatch(removePartialViewSpinner(spinnerId));
						});
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const attachEventListeners = () => {
		if (containerRef && containerRef.current) {
			const inputs = containerRef.current.querySelectorAll(
				"input, select, checkbox",
			);
			inputs.forEach(input => {
				input.addEventListener("change", changeTemplateValue);
			});
		}
	};
	const removeEventListeners = () => {
		if (containerRef && containerRef.current) {
			const inputs = containerRef.current.querySelectorAll(
				"input, select, checkbox",
			);
			inputs.forEach(input => {
				input.removeEventListener("change", changeTemplateValue);
			});
		}
	};

	const getDocumentCategory = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentCategory()
			.then(response => {
				if (response && response.data) {
					setDocumentType(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const uploadFile = event => {
		const file = event.target.files[0];
		if (file) {
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedFile => {
					setUploadedFile(uploadedFile);
					setFile(file);
				})
				.catch(
					error =>
						error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							translationService.translate("TR_INVALID_FILE_FORMAT_EXC"),
						),
				);
		}
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const onSelectOptionChange = (selectedItem, fieldName) => {
		if (fieldName === "documentTypeId") {
			const spinnerId = uuid();
			// dispatch(addPartialViewSpinner(spinnerId));
			ApiService.getDocumentTemplatesByCategoryId(selectedItem.id)
				.then(response => {
					if (response && response.data) {
						setTemplates(response.data);
						setDocumentTypeId(selectedItem.id);

						// const documentData = JSON.parse(selectedItem.body);
					}
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					// dispatch(removePartialViewSpinner(spinnerId));
				});
		}
		if (fieldName === "documentTemplateId") {
			const spinnerId = uuid();
			// dispatch(addPartialViewSpinner(spinnerId));
			ApiService.getDocumentTemplateInfoInTemplateId(selectedItem.id)
				.then(response => {
					if (response && response.data) {
						setDocumentTemplateId(selectedItem.id);
						setTemplateData(selectedItem);
						setTemplateInfo(response.data);
						setTemplateInfoData(response.data, selectedItem);
					}
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					// dispatch(removePartialViewSpinner(spinnerId));
				});
		}
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setValues(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const removeImg = () => {
		setUploadedFile(null);
		setFile(null);
	};

	const onChange = (event, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setIsInvalidSubmit(false);
		cb(event.target.value);
	};

	const onSubmit = () => {
		var stateCopy = { ...values };
		if (!documentName || (documentName && !documentName.trim().length)) {
			setIsInvalidSubmit(true);
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		if (documentTypeId === 2) {
			stateCopy.tableData = [];
		} else {
			stateCopy.tableData.forEach(tableDataItem => {
				tableDataItem.number = tableDataItem.number.toString();
				tableDataItem.columnNumber = tableDataItem.columnNumber.toString();
			});
		}
		const formData = new FormData();
		dispatch(addButtonSpinner(buttonSpinnerId));

		const form = {
			file,
			description: description.trim(),
			documentName: documentName.trim(),
		};

		if (file) {
			formData.append("documentFile", form.file);
		}

		!uploadedFilePath && formData.append("data", JSON.stringify(stateCopy));

		formData.append("documentTemplateId", documentTemplateId);

		// !uploadedFilePath && formData.append("documentCategoryId", documentCategoryId);
		formData.append("documentCategoryId", documentTypeId);
		form.documentName && formData.append("name", form.documentName);
		form.description && formData.append("description", form.description);
		documentId && formData.append("id", documentId);

		(documentId
			? ApiService.updateDocument(formData)
			: ApiService.createDocument(formData)
		)
			.then(response => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(
						documentId
							? "TR_DOCUMENT_SUCCESSFULLY_UPDATED"
							: "TR_DOCUMENT_SUCCESSFULLY_CREATED",
					),
				);
				props.history.push(`/${language}/${DOCUMENT_KEY}`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		props.history.push(`/${language}/${DOCUMENT_KEY}`);
	};

	const setTemplateInfoData = (
		templateInfo,
		templateData,
		documentBodyData = null,
	) => {
		let documentData = null;
		if (documentBodyData) {
			documentData = { ...values, ...JSON.parse(documentBodyData) };
			setValues(values => ({ ...values, ...documentData }));
		} else {
			documentData = values;
		}
		let tableListDatasObs, tableColumnCount;

		templateInfo.forEach((data, index) => {
			const position = data.position;

			if (
				data.type === "table-input-string" ||
				data.type === "table-input-date" ||
				data.type === "table-input-number" ||
				data.type === "table-library" ||
				data.type === "table-list" ||
				data.type === "table-sign-part" ||
				data.type === "table-number-and-date" ||
				data.type === "table-input-start-end-time" ||
				data.type === "remove-row-button"
			) {
				setTableTds(tds => [...tds, `<td>{a${data.position}}</td>`]);
				if (documentData.tableData.length) {
					templateData.body = templateData.body
						.replace(
							`<td>{a${data.position}}</td>`,
							`<td class="col${data.position}-${documentData.tableData[0].number}">{a${data.position}}</td>`,
						)
						.replace(
							`<td>№ {a${data.position}}</td>`,
							`<td class="col${data.position}-${documentData.tableData[0].number}">№ {a${data.position}}</td>`,
						);
				}
			}

			switch (data.type) {
				case "organization-name":
					documentData[`a${position}`] = user.organizationName;
					templateData.body = templateData.body.replace(
						`<p>{a${position}}</p>`,
						`<input id="a${position}" name="a${position}" data-type="${
							data.type
						}" autocomplete="off" class="custom-input organization-title center"  value="${
							documentData[`a${position}`]
						}">`,
					);
					break;

				case "input-create-date":
					templateData.body = templateData.body.replace(
						`<p>{a${position}}</p>`,
						`<input id="a${position}" name="a${position}" data-type="${data.type}" type="date" autocomplete="off" class="custom-input date-input">`,
					);
					break;

				case "input-date":
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<input id="a${position}" name="a${position}" data-type="${data.type}" type="date" autocomplete="off" class="custom-input date-input bottom-short-mrg">`,
					);
					break;

				case "input-number":
					templateData.body = templateData.body.replace(
						`<p>№ {a${position}}</p>`,
						`<div class="direction-right"><span class="number-label">№</span><input id="a${position}" data-type="${data.type}" name="a${position}" type="number" autocomplete="off" class="custom-input doc-num"  value="this.value = Math.round(this.value)"></div>`,
					);
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<input id="a${position}" name="a${position}" type="number" data-type="${data.type}" autocomplete="off" class="custom-input doc-num">`,
					);
					break;
				case "input-date-to-right":
					templateData.body = templateData.body.replace(
						`<p>{a${position}}</p>`,
						`<div class="direction-right"><input id="a${position}" data-type="${data.type}"   name="a${position}" type="date" autocomplete="off" class="custom-input date-input bottom-short-mrg"></div>`,
					);
					break;
				case "input-string":
					if (!data.placeholder) {
						data.placeholder = "";
					}
					templateData.body = templateData.body.replace(
						`<h2>{a${position}}</h2>`,
						`<h2 class="center selected-font"><input id="a${position}" data-type="${data.type}"  name="a${position}" onchange="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg"></h2>`,
					);
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<input id="a${position}" name="a${position}" type="text" data-type="${data.type}"  autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height">`,
					);
					break;
				case "table-input-string":
					if (!documentData.tableData.length) {
						if (!data.placeholder) {
							data.placeholder = "";
						}
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<input id="col${position}-1" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-table-input">`,
						);
					}
					break;

				case "table-input-number":
					if (!documentData.tableData.length) {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<input id="col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">`,
						);
					}
					break;
				case "table-input-date":
					if (!documentData.tableData.length) {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<input id="col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`,
						);
					}
					break;
				case "table-number-and-date":
					if (!documentData.tableData.length) {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`№<input id="0-col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`,
						);
					}
					break;
				case "table-input-start-end-time":
					if (!documentData.tableData.length) {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<div class="flexable"><input id="startcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"></div>`,
						);
					}
					break;
				case "input-short-string":
					if (!data.placeholder) {
						data.placeholder = "";
					}
					templateData.body = templateData.body.replace(
						`<p>{a${position}}</p>`,
						`<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input date-input bottom-short-mrg">`,
					);
					break;
				case "input-long-string":
					if (!data.placeholder) {
						data.placeholder = "";
					}
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input fit-height">`,
					);
					break;
				case "inputs-list":
					if (documentData && documentBodyData) {
						const inputsData = documentData[`a${position}`];
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="${inputsData[0]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
						);
						for (let i = 0; i < inputsData.length; i++) {
							if (!i) {
								continue;
							}
							templateData.body = templateData.body.replace(
								`<input id="inputs-list-${i - 1}" value="${
									inputsData[i - 1]
								}" type="text" autocomplete="off" placeholder="${
									data.placeholder
								}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
								`<input id="inputs-list-${i - 1}" value="${
									inputsData[i - 1]
								}" type="text" autocomplete="off" placeholder="${
									data.placeholder
								}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${i}" value="${
									inputsData[i]
								}" type="text" autocomplete="off" placeholder="${
									data.placeholder
								}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
							);
						}
						templateData.body = templateData.body.replace(
							`<input id="inputs-list-${inputsData.length - 1}" value="${
								inputsData[inputsData.length - 1]
							}" type="text" autocomplete="off" placeholder="${
								data.placeholder
							}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
							`<input id="inputs-list-${inputsData.length - 1}" value="${
								inputsData[inputsData.length - 1]
							}" type="text" autocomplete="off" placeholder="${
								data.placeholder
							}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${
								inputsData.length
							}" value="" type="text" autocomplete="off" placeholder="${
								data.placeholder
							}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
						);
						setInputsListData(inputsData);
					} else {
						if (!data.placeholder) {
							data.placeholder = "";
						}
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
						);
					}
					break;

				case "sign-row":
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height">`,
					);

					break;
				case "drop-down-group":
				case "drop-down-group-not-draw":
					ApiService.getAllStudentDivisions()
						.then(response => {
							let groupData = response.data;
							setGroupData(groupData);

							let str = setDropDownValue(groupData, position, null);
							templateData.body = templateData.body.replace(
								`{a${position}}`,
								str,
							);
							for (let i in documentData) {
								if (
									documentData[i] &&
									groupData.find(data => data.groupName === documentData[i])
								) {
									templateData.body = templateData.body.replace(
										`>${documentData[i]}`,
										`selected >${documentData[i]}`,
									);
								}
							}
							const selectedTemplateInfo = templateInfo.find(
								data => data.position === position + 1,
							);
							if (
								documentBodyData &&
								documentData &&
								selectedTemplateInfo.type === "students-list"
							) {
								const studentsData = documentData[`a${position + 1}`];
								const selectedGroup = groupData.find(
									group => group.groupName === documentData[`a${position}`],
								);
								if (selectedGroup) {
									getGroupStudents(
										selectedGroup.id,
										`a${position}`,
										studentsData,
									);
								}
							}
						})
						.catch(error => {
							AlertService.alert(
								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
								error,
							);
						});
					break;
				case "library":
					ApiService.getLibrarydataByLibId(data.entityId)
						.then(response => {
							let str = setDropDownValue(
								response.data,
								position,
								null,
								values[`a${position}`],
							);
							templateData.body = templateData.body.replace(
								`{a${position}}`,
								str,
							);
						})
						.catch(error => {
							AlertService.alert(
								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
								error,
							);
						});
					break;
				case "table-library":
					if (!documentData.tableData.length) {
						ApiService.getLibrarydataByLibId(data.entityId)
							.then(response => {
								const libraryData = response.data;
								tableLibDatas[position] = libraryData;
								setTableLibDatas(tableLibDatas);

								let str = setDropDownValue(libraryData, position, 1);
								str = str.replace(`id="${position}"`, `id="col${position}-1"`);
								templateData.body = templateData.body.replace(
									`{a${position}}`,
									str,
								);
							})
							.catch(error => {
								AlertService.alert(
									AlertService.checkMessageType(error.respcode) || ERROR_KEY,
									error,
								);
							});
					}
					break;
				case "table-list":
					if (!documentData.tableData.length) {
						ApiService.getDocumentTemplateInfoData(data.id)
							.then(response => {
								const tableListData = response.data;
								tableListDates[position] = tableListData;
								setTableListDates(tableListDates);
								let str = setDropDownValue(
									tableListDates[position],
									position,
									1,
								);
								str = str.replace(`id="${position}"`, `id="col${position}-1"`);
								templateData.body = templateData.body.replace(
									`{a${position}}`,
									str,
								);
							})
							.catch(error => {
								AlertService.alert(
									AlertService.checkMessageType(error.respcode) || ERROR_KEY,
									error,
								);
							});
					}
					break;
				case "list":
					ApiService.getDocumentTemplateInfoData(data.id)
						.then(response => {
							let listData = response.data;
							let str = setDropDownValue(listData, position, null);
							templateData.body = templateData.body.replace(
								`{a${position}}`,
								str,
							);
							for (let i in documentData) {
								if (
									documentData[i] &&
									listData.find(data => data.data === documentData[i])
								) {
									templateData.body = templateData.body.replace(
										`>${documentData[i]}`,
										` selected >${documentData[i]}`,
									);
								}
							}
						})
						.catch(error => {
							AlertService.alert(
								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
								error,
							);
						});
					break;
				case "text-area":
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<textarea id="a${position}" class="text-area" placeholder="Ввести текст"></textarea>`,
					);
					break;
				case "add-row-button":
					templateData.body = templateData.body.replace(
						`{a${position}}`,
						`<button id="add-${position}" type="button" class="table-row-add">Add</button>`,
					);
					break;
				case "remove-row-button":
					if (!documentData.tableData.length) {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<button id="remove-${position}-1" type="button" class="table-row-add">Remove</button>`,
						);
					}
					break;
				case "sign-row-arr":
					if (documentBodyData && documentData) {
						const inputsData = documentData[`a${position}`];
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<div id="a${position}" class="input-list"><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-0" value="${inputsData[0]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div></div>`,
						);
						for (let i = 0; i < inputsData.length; i++) {
							if (!i) {
								continue;
							}
							templateData.body = templateData.body.replace(
								`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
									i - 1
								}" value="${
									inputsData[i - 1]
								}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
								`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
									i - 1
								}" value="${
									inputsData[i - 1]
								}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${i}" value="${
									inputsData[i]
								}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
							);
						}
						templateData.body = templateData.body.replace(
							`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
								inputsData.length - 1
							}" value="${
								inputsData[inputsData.length - 1]
							}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
							`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
								inputsData.length - 1
							}" value="${
								inputsData[inputsData.length - 1]
							}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
								inputsData.length
							}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
						);
					} else {
						templateData.body = templateData.body.replace(
							`{a${position}}`,
							`<div id="a${position}" class="input-list"><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-0" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div></div>`,
						);
					}
					break;
				case "table-sign-part":
					templateData.body = templateData.body.replace(
						`{a${data.position}}`,
						`<div id="col${position}-1"></div>`,
					);

					break;

				default:
					// Здесь можно добавить обработку типов, которые не были перечислены
					break;
			}
			if (data.hidden) {
				if (documentBodyData && documentData && data.type === "students-list") {
					const studentsData = documentData[`a${position}`];
				}
				if (templateInfo[index - 1].entityId === 5) {
					templateData.body = templateData.body.replace(
						`id="a${position}"`,
						`id="entity-a${position}" class="hidden"`,
					);
				} else {
					templateData.body = templateData.body.replace(
						`id="a${position}"`,
						`id="a${position}" class="hidden"`,
					);
					templateData.body = templateData.body.replace(
						`<p>{a${position}}</p>`,
						`<p class="hidden">{a${position}}</p>`,
					);
				}
			}
		});
		if (documentData.tableData?.length) {
			let { tableData } = documentData;
			let templateBody = templateData.body
				.replace("<tr>", `<tr class="row-th">`)
				.replace("<tr>", `<tr class="row-${tableData[0].number}">`);

			const replaceTemplateBody = (infoData, data, inputType, value = "") => {
				const classAttr = `col${infoData.position}-${data.number}`;
				templateBody = templateBody.replace(
					`<td class="${classAttr}">{a${infoData.position}}</td>`,
					`<td class="${classAttr}"><input id="col${infoData.position}-${data.number}" value="${value}" name="a${infoData.position}" type="${inputType}" autocomplete="off" class="custom-input"></td>`,
				);
			};

			tableData.forEach((data, index) => {
				if (!templateBody.includes(`<tr class="row-${data.number}">`)) {
					tableRowCount++;
					let row = `<tr class="row-${data.number}"><td>${tableRowCount}</td></tr>`;

					tableTds.forEach(tdItem => {
						const column = tdItem.substring(
							tdItem.indexOf("{a") + 2,
							tdItem.indexOf("}"),
						);
						tdItem = tdItem.replace(
							"<td>",
							`<td class="col${column}-${data.number}">`,
						);
						row = row.replace("</tr>", `${tdItem}</tr>`);
					});

					templateBody = templateBody.replace("</tbody>", `${row}</tbody>`);
					setTableRowCount(tableRowCount);
				}

				const infoData = templateInfo.find(
					info => info.position.toString() === data.columnNumber,
				);

				if (infoData?.type === "table-number-and-date") {
					const [numberValue, dateValue] = data.value || [];
					templateBody = templateBody.replace(
						`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`,
						`№<input id="0-col${infoData.position}-${data.number}" value="${
							numberValue || ""
						}" type="number"> от <input id="1-col${infoData.position}-${
							data.number
						}" value="${dateValue || ""}" type="date">`,
					);
				} else if (infoData?.type === "table-input-number") {
					replaceTemplateBody(infoData, data, "number", data.value);
				} else if (infoData?.type === "table-input-date") {
					replaceTemplateBody(infoData, data, "date", data.value);
				} else if (data.timeType === "start") {
					const endValue = tableData.find(
						item =>
							item.timeType === "end" &&
							item.columnNumber === infoData.position &&
							item.number === data.number,
					)?.value;
					templateBody = templateBody.replace(
						`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`,
						`<div class="flexable"><input id="startcol${infoData.position}-${
							data.number
						}" value="${data.value}" type="time"> - <input id="endcol${
							infoData.position
						}-${data.number}" value="${endValue || ""}" type="time"></div>`,
					);
				} else if (infoData?.type === "table-input-string") {
					const placeholder = infoData.placeholder || "";
					replaceTemplateBody(infoData, data, "text", data.value);
				}
			});

			templateInfo.forEach(info => {
				if (["table-library", "table-list"].includes(info.type)) {
					const serviceCall =
						info.type === "table-library"
							? ApiService.getLibrarydataByLibId(info.entityId)
							: ApiService.getDocumentTemplateInfoData(info.id);

					serviceCall.then(response => {
						if (info.type === "table-library") {
							tableLibDatas[info.position] = response.data;
							setTableLibDatas(tableLibDatas);
						} else {
							tableListDates[info.position] = response;
							tableListDatasObs = tableListDates;
						}
					});
				}
			});

			templateData.body = templateBody;
		}

		if (documentData) {
			Object.keys(documentData).forEach(i => {
				if (documentData[i]) {
					// Замена для input с id "entity-{i}"
					templateData.body = templateData.body.replace(
						new RegExp(`<input id="entity-${i}" class="hidden"`, "g"),
						`<input id="entity-${i}" value="${documentData[i]}"`,
					);

					// Замена для input с id "{i}"
					templateData.body = templateData.body.replace(
						new RegExp(`<input id="${i}"`, "g"),
						`<input id="${i}" value="${documentData[i]}"`,
					);

					// Замена для textarea с id "{i}"
					templateData.body = templateData.body.replace(
						new RegExp(
							`<textarea id="${i}" class="text-area" placeholder="Ввести текст"></textarea>`,
							"g",
						),
						`<textarea id="${i}" class="text-area" placeholder="Ввести текст">${documentData[i]}</textarea>`,
					);
				}
			});
		}
		templateData.body = templateData.body.replace(
			"<table",
			'<div class="template-table-container"><table class="template-table"',
		);
		templateData.body = templateData.body.replace("</table>", "</table></div>");
		templateData.body = templateData.body.replace(
			"<h1>",
			'<h1 class="center template-title">',
		);
		templateData.body = templateData.body.replace(
			"<h3>",
			'<h3 class="center template-title margin-h3">',
		);
		templateData.body = templateData.body.replace(
			"<h3>",
			'<h3 class="doc-form-sub-title">',
		);
		templateData.body = templateData.body.replace(
			'<h3 class="center template-title margin-h3"><strong>',
			'<h3 class="temp-sub-title"><strong>',
		);
		templateData.body = templateData.body.replace(
			"<h2>",
			'<h2 class="temp-sub-title center selected-font">',
		);

		templateData.body = templateData.body
			.split("<p>")
			.join('<p class="flex-wrap">');

		return templateData;
	};

	const buttonClick = async event => {
		if (event.target.type !== "button") {
			return;
		}

		if (event.target.id.includes("add")) {
			addTableRow();
		} else if (event.target.id.includes("remove")) {
			const row = event.target.id.substring(
				event.target.id.lastIndexOf("-") + 1,
			);

			removeTableRow(row);
		}
	};

	const addTableRow = () => {
		if (containerRef && containerRef.current) {
			let body = containerRef.current.innerHTML;
			const newRow = `<tr id="row-${tableRowCount + 1}"><td>${
				tableRowCount + 1
			}</td>${tableTds
				.map(tdItem =>
					generateTableElement(
						getTemplateInfo(extractPositionNumber(tdItem)),
						tableRowCount + 1,
					),
				)
				.join("")}</tr>`;
			body = body.replace("</tbody>", `${newRow}</tbody>`);
			setTableRowCount(tableRowCount + 1);
			setTemplateData(prev => ({ ...prev, body }));

			// return new Promise((res, rej) => {
			// 	res({ rowCount: 0, templateData });
			// });
		}
	};

	const extractPositionNumber = tdItem => {
		const firstIndex = tdItem.indexOf("{") + 2;
		const lastIndex = tdItem.indexOf("}");
		return tdItem.substring(firstIndex, lastIndex);
	};

	const getTemplateInfo = positionNumber => {
		return templateInfo.find(
			data => data.position.toString() === positionNumber,
		);
	};
	const getTemplateInfoByType = type => {
		return templateInfo.find(data => data.type === type);
	};

	const generateTableElement = (templateInfo, rowCount) => {
		const position = templateInfo.position;
		switch (templateInfo.type) {
			case "table-input-string":
				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="text" autocomplete="off" placeholder="${
					templateInfo.placeholder || ""
				}" class="custom-input template-table-input"></td>`;

			case "table-input-date":
				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input"></td>`;

			case "table-input-number":
				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`;

			case "table-number-and-date":
				return `<td>№<input id="0-col${position}-${rowCount}" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input"> от <input id="1-col${position}-${rowCount}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input"></td>`;

			case "table-input-start-end-time":
				return `<td><div class="flexable"><input id="startcol${position}-${rowCount}" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${position}-${rowCount}" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`;

			case "table-library":
			case "table-list":
				const dropdownData =
					templateInfo.type === "table-library"
						? tableLibDatas[position]
						: tableListDates[position];
				let dropdown = setDropDownValue(dropdownData, position, rowCount);
				return `<td>${dropdown.replace(
					`id="${position}"`,
					`id="col${position}-${rowCount}"`,
				)}</td>`;

			case "table-sign-part":
				return `<td><div id="col${position}-${rowCount}"></div></td>`;

			case "remove-row-button":
				return `<td><button id="remove-${position}-${rowCount}" type="button" class="table-row-add">Remove</button></td>`;

			default:
				return "<td></td>"; // Если тип не поддерживается, возвращаем пустую ячейку
		}
	};
	const removeTableRow = row => {
		const regex = new RegExp(`<tr id="row-${row}".*?</tr>`, "gs");
		templateData.body = templateData.body.replace(regex, "");

		const newTableData = tableData.filter(
			data => data.number !== row.toString(),
		);
		setTableData(newTableData);
		if (tableRowCount === 1) {
		} else {
			setTableRowCount(tableRowCount - 1);
		}

		setTemplateData(templateData);

		return new Promise((res, rej) => {
			res({ row, templateData });
		});
	};

	const setDropDownValue = (arr, ind, row, value, isStudent) => {
		if (!arr) {
			return;
		}
		if (!arr.length) {
			return `<select name="a${ind}" disabled id="${ind}" class="select-list"></select>`;
		}
		let str = "";
		if (row) {
			if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
				str = `<select name="a${ind}" id="col${ind}-${row}" class="select-list"><option selected disabled hidden></option></select>`;
			} else {
				str = `<select name="a${ind}" id="col${ind}-${row}" class="select-list"><option selected disabled hidden></option></select>`;
			}
			arr.forEach((item, i) => {
				str = str = str.replace(
					"</option></select>",
					`</option><option value="${item.name || item.groupName || item.data}"
					  ${
							value &&
							value ===
								(item.name || item.groupName || item.data ? "selected" : "")
						}  id="col${ind}-${row}-${i}">${
						item.name || item.groupName || item.data
					}</option></select>`,
				);
			});
		} else {
			if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
				str = `<select name="a${ind}" id="a${ind}" data-type="isStudent"   class="select-list"><option selected disabled hidden></option></select>`;
			} else {
				str = `<select name="a${ind}" id="a${ind}" data-type="isStudent"  class="select-list"><option selected disabled hidden></option></select>`;
			}
			arr.forEach((item, i) => {
				str = str = str.replace(
					"</option></select>",
					`</option><option value="${
						item.name || item.groupName || item.data
					}" ${
						value &&
						value ===
							(item.name || item.groupName || item.data ? "selected" : "")
					}  id="col${ind}-${row}-${i}">${
						item.name || item.groupName || item.data
					}</option></select>`,
				);
			});
		}
		return str;
	};

	const getGroupStudents = (id, position, studentsData = []) => {
		if (id) {
			ApiService.getDivisionsStudents(id, false).then(response => {
				let students = response.data;
				setStudents(students);
				const idNumberPart = position.substr(1);
				const index = +idNumberPart + 1;
				let studentsList = [];
				if (studentsData) {
					studentsList = studentsData;
				}
				setValues(values => ({
					...values,
					[`a${index}`]: studentsList,
				}));
				templateData.body = templateData.body.replace(
					`<div id="students-`,
					`<div class="hidden" id="students-`,
				);

				if (students.length) {
					if (!studentsData) {
						students.forEach(student =>
							studentsList.push(
								`${student.lastname} ${student.firstname} ${
									student.middlename ? student.middlename : ""
								}`,
							),
						);
					}
					const studentsInfo = templateInfo.find(
						data => data.type === "students-list",
					);
					templateData.body = templateData.body.replace(
						`<div id="students-`,
						`<div class="hidden" id="students-`,
					);
					if (
						templateData.body.includes(
							`<div class="hidden" id="students-${id}"`,
						)
					) {
						templateData.body = templateData.body.replace(
							`<div class="hidden" id="students-${id}"`,
							`<div id="students-${id}"`,
						);
						values[`a${index}`].forEach((name, ind) => {
							if (ind < -1) {
								return;
							}
							const chechBoxIndex = ind + 1;
							if (
								templateData.body.includes(
									`<input type="checkbox" id="${id}-${chechBoxIndex}">`,
								)
							) {
								values[`a${index}`][ind] = "";
							}
						});
						values[`a${index}`].forEach((name, ind) => {
							if (ind < -1) {
								return;
							}
							if (!name) {
								values[`a${index}`].splice(ind, 1);
							}
						});
					} else {
						if (studentsData && studentsData.length) {
							let str;
							for (let i = 0; i < students.length; i++) {
								for (let j = 0; j < studentsData.length; j++) {
									if (
										!j &&
										studentsData[j] ===
											`${students[i].lastname} ${students[i].firstname} ${
												students[i].middlename ? students[i].middlename : ""
											}`
									) {
										str = `<div id="students-${id}" class="students-container"><input checked type="checkbox" id="${id}-${
											i + 1
										}"><label for="${id}-${i + 1}">${students[i].lastname} ${
											students[i].firstname
										} ${
											students[i].middlename ? students[i].middlename : ""
										}</label><br></div>`;
									} else if (
										studentsData[j] ===
											`${students[i].lastname} ${students[i].firstname} ${
												students[i].middlename ? students[i].middlename : ""
											}` &&
										str
									) {
										str = str.replace(
											"</label><br></div>",
											`</label><br><input checked type="checkbox" id="${id}-${
												i + 1
											}"><label for="${id}-${i + 1}">${students[i].lastname} ${
												students[i].firstname
											} ${
												students[i].middlename ? students[i].middlename : ""
											}</label><br></div>`,
										);
									}
								}
							}
							for (let i = 0; i < students.length; i++) {
								if (
									str &&
									!str.includes(
										`<input checked type="checkbox" id="${id}-${
											i + 1
										}"><label for="${id}-${i + 1}">${students[i].lastname} ${
											students[i].firstname
										} ${
											students[i].middlename ? students[i].middlename : ""
										}</label>`,
									)
								) {
									str = str.replace(
										"</label><br></div>",
										`</label><br><input type="checkbox" id="${id}-${
											i + 1
										}"><label for="${id}-${i}">${students[i].lastname} ${
											students[i].firstname
										} ${
											students[i].middlename ? students[i].middlename : ""
										}</label><br></div>`,
									);
								}
							}
							if (studentsInfo) {
								templateData.body = templateData.body.replace(
									`<p class="hidden">{a${studentsInfo.position}}</p>`,
									str,
								);
							}
							templateData.body = templateData.body.replace(
								`<div class="hidden" id="students-`,
								`${str}<div class="hidden" id="students-`,
							);
						} else {
							let str = `<div id="students-${id}" class="students-container"><input checked type="checkbox" id="${id}-1"><label for="${id}-1">${
								students[0].lastname
							} ${students[0].firstname} ${
								students[0].middlename ? students[0].middlename : ""
							}</label><br></div>`;
							for (let i = 1; i < students.length; i++) {
								str = str.replace(
									"</label><br></div>",
									`</label><br><input checked type="checkbox" id="${id}-${
										i + 1
									}"><label for="${id}-${i + 1}">${students[i].lastname} ${
										students[i].firstname
									} ${
										students[i].middlename ? students[i].middlename : ""
									}</label><br></div>`,
								);
							}
							templateData.body = templateData.body.replace(
								`<p class="hidden">{a${studentsInfo.position}}</p>`,
								str,
							);
							templateData.body = templateData.body.replace(
								`<div class="hidden" id="students-`,
								`${str}<div class="hidden" id="students-`,
							);
						}
					}
				}
			});
			setStudentsList(studentsList);
			setTemplateData(templateData);
		}
	};

	const setFormValues = event => {
		let cloneGroupData;
		if (groupData.length) {
			cloneGroupData = groupData.find(data => data.name === event.target.value);
		}
		const lastIndex = event.target.id.lastIndexOf("-") + 2;
		const nextIndex = +event.target.id.substr(1) + 1;
		if (event.target.value === "Другое (вписать вручную)") {
			templateData.body = templateData.body.replace(
				`id="entity-a${nextIndex}" class="hidden"`,
				`id="entity-a${nextIndex}"`,
			);
		} else if (
			templateData.body.includes(`id="entity-a${nextIndex}" class="hidden"`) &&
			templateData.body.includes(`id="entity-a${nextIndex}"`)
		) {
			templateData.body = templateData.body.replace(
				`id="entity-a${nextIndex}"`,
				`id="entity-a${nextIndex}" class="hidden" value=""`,
			);
			setValues(values => ({
				...values,
				[`a${nextIndex}`]: "",
			}));
		}
		if (cloneGroupData) {
			if (
				event.target.type === "select-one" &&
				event.target.type !== "checkbox"
			) {
				setOptionSelectedAttr(event.target);
			}
			setValues(values => ({
				...values,
				[`a${nextIndex}`]: event.target.value,
			}));
			if (templateInfo.find(data => data.type === "students-list")) {
				getGroupStudents(groupData.id, event.target.id, null);
			}
			if (templateInfo.find(data => data.type === "students-in-table")) {
				setGroupsStudentsInTable(groupData.id);
			}
		} else if (event.target.type === "checkbox") {
			hasStudentChecked(event.target.id, event.target.checked);
		} else if (event.target.id.includes("inputs-list")) {
			const templateInfo = templateInfo.find(
				data => data.type === "inputs-list",
			);
			const secondtemplateInfo = templateInfo.find(
				data => data.type === "sign-row-arr",
			);
			setValues(values => ({
				...values,
				[`a${templateInfo.position}`]: inputsListData,
			}));
			if (secondtemplateInfo) {
				setValues(values => ({
					...values,
					[`a${secondtemplateInfo.position}`]: inputsListData,
				}));
			}
		} else if (event.target.id.includes("entity")) {
			const itemId = event.target.id.substr(lastIndex);
			setValues(values => ({
				...values,
				[`a${itemId}`]: event.target.value,
			}));
		} else if (event.target.id) {
			if (
				event.target.type === "select-one" &&
				event.target.type !== "checkbox" &&
				event.target.type !== "button"
			) {
				setOptionSelectedAttr(event.target);
				if (event.target.id.includes("col")) {
					setTableValue(event.target.id, event.target.value, null);
				}
			}
			if (!event.target.id.includes("col")) {
				setValues(values => ({
					...values,
					[`${event.target.id}`]: event.target.value,
				}));
			}
		}
		setTemplateData(templateData);
	};

	const setOptionSelectedAttr = target => {
		const optionIdPart = target.id.substr(1);
		if (templateData.body.includes(`<option selected id="${optionIdPart}`)) {
			templateData.body = templateData.body.replace(
				`<option selected id="${optionIdPart}`,
				`<option id="${optionIdPart}`,
			);
		}
		const options = target.querySelectorAll("option");
		let optionId;
		options.forEach(option => {
			if (option.selected) {
				optionId = option.id;
			}
		});

		templateData.body = templateData.body.replace(
			`<option id="${optionId}">${target.value}</option>`,
			`<option selected id="${optionId}">${target.value}</option>`,
		);
		setTemplateData(templateData);
	};

	const setTableValue = (strId, value, timeType) => {
		const dashIndex = strId.lastIndexOf("-") + 1;
		const dataIndex = strId.substr(dashIndex);
		const columnNumber = strId.substring(strId.indexOf("l") + 1, dashIndex - 1);
		const tableItemData = tableData.find(
			data =>
				data.number == dataIndex &&
				data.columnNumber == columnNumber &&
				timeType == data.timeType,
		);
		const tableItemDataIndex = tableData.indexOf(
			data =>
				data.number == dataIndex &&
				data.columnNumber == columnNumber &&
				timeType == data.timeType,
		);
		if (tableItemData) {
			const info = templateInfo.find(
				info => info.position.toString() === tableItemData.columnNumber,
			);
			if (
				(info && info.type === "table-string-and-date") ||
				(info && info.type === "table-number-and-date")
			) {
				if (value) {
					tableItemData.value[strId[0]] = value;
				} else {
					if (tableItemData.value.length > 1) {
						tableItemData.value.splice(strId[0], 1);
					} else {
						tableItemData.value = [];
					}
					if (!tableItemData.value.length) {
						setTableData(tableData => tableData.splice(tableItemDataIndex, 1));
					}
				}
			} else {
				if (value) {
					tableItemData.value = value;
				} else {
					setTableData(tableData => tableData.splice(tableItemDataIndex, 1));
				}
			}
		} else if (value) {
			const info = templateInfo.find(
				info => info.position.toString() === columnNumber,
			);
			if (
				(info && info.type === "table-string-and-date") ||
				(info && info.type === "table-number-and-date")
			) {
				const arr = [];
				arr[strId[0]] = value;
				setTableData(tableData => {
					tableData.push({
						number: dataIndex,
						columnNumber: columnNumber,
						value: arr,
						timeType: timeType,
					});
					return tableData;
				});
			} else {
				setTableData(tableData => {
					tableData.push({
						number: dataIndex,
						columnNumber: columnNumber,
						value: value,
						timeType: timeType,
					});
					return tableData;
				});
			}
		}
	};

	const hasStudentChecked = (id, checked) => {
		if (
			templateData.body.includes(`<input checked type="checkbox" id="${id}">`)
		) {
			templateData.body = templateData.body.replace(
				`<input checked type="checkbox" id="${id}">`,
				`<input type="checkbox" id="${id}">`,
			);
		} else {
			templateData.body = templateData.body.replace(
				`<input type="checkbox" id="${id}">`,
				`<input checked type="checkbox" id="${id}">`,
			);
		}
		id = +id.substr(id.indexOf("-") + 1);
		let index = id - 1;
		const studentName = `${students[index].lastname} ${
			students[index].firstname
		} ${students[index].middlename ? students[index].middlename : ""}`;
		if (checked) {
			if (studentName !== studentsList[index]) {
				studentsList.splice(index, 0, studentName);
			}
		} else {
			const index = studentsList.indexOf(studentName);
			if (index > -1) {
				studentsList.splice(index, 1);
			}
		}
		setTemplateData(templateData);
	};

	const setGroupsStudentsInTable = groupId => {
		const columnStr = tableTds[0];
		const firstIndex = columnStr.indexOf("{") + 2;
		const lastIndex = columnStr.indexOf("}");
		const position = columnStr.substring(firstIndex, lastIndex);
		if (groupId) {
			ApiService.getDivisionsStudents(groupId, false).then(response => {
				let studentsInTable = response.data;
				while (tableRowCount > 1) {
					let cloneTableRowCount = tableRowCount;
					removeTableRow(cloneTableRowCount.toString());
					// setTableRowCount(cloneTableRowCount--);
				}
				removeTableRow(tableRowCount.toString());
				templateData.body = templateData.body.replace(
					`id="col${position}-${tableRowCount}"`,
					`id="col${position}-${tableRowCount}" value="${studentsInTable[0].studentName}"`,
				);
				setTableValue(
					`col${position}-${tableRowCount}`,
					studentsInTable[0].studentName,
					"",
				);
				studentsInTable.forEach((student, index) => {
					if (index) {
						addTableRow();
						templateData.body = templateData.body.replace(
							`id="col${position}-${tableRowCount}"`,
							`id="col${position}-${tableRowCount}" value="${student.studentName}"`,
						);
						setTableValue(
							`col${position}-${tableRowCount}`,
							student.studentName,
							"",
						);
					}
				});
				setTemplateData(templateData);
			});
		}
	};

	const getDivisionsStudents = async (id, html) => {
		let response = "";
		try {
			response = await ApiService.getDivisionsStudents(id, false).then(
				response => {
					if (response && response.data && response.data.length) {
						return response.data;
					}
				},
			);
		} catch (error) {
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		}
		return response;

		// ApiService.getDivisionsStudents(id, false)
		// 	.then(response => {
		// 		if (response && response.data && response.data.length) {
		// 		}
		// 	})
		// 	.catch(error => {
		// 		error &&
		// 			AlertService.alert(
		// 				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
		// 				error,
		// 			);
		// 	});
	};

	const focusOut = event => {
		let tableScrollLeft = 0;
		let tableBlock = document.querySelector(".template-table-container");
		if (document.querySelector(".template-table-container"))
			tableScrollLeft = tableBlock.scrollLeft;

		if (event.target.id.includes("inputs-list")) {
			const templateInfo = templateInfo.find(
				data => data.type === "inputs-list",
			);
			setInputsListValues(
				event.target.id,
				event.target.value,
				templateInfo.placeholder,
			);
		} else if (event.target.type === "textarea") {
			if (
				templateData.body.includes(
					`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст"></textarea>`,
				)
			) {
				templateData.body = templateData.body.replace(
					`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст"></textarea>`,
					`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${event.target.value}</textarea>`,
				);
			} else {
				const valueIndex = templateData.body.indexOf(`</textarea>`);
				let str = templateData.body.substr(0, valueIndex);
				const valueLastIndex = str.lastIndexOf(">") + 1;
				str = str.substr(valueLastIndex);
				templateData.body = templateData.body.replace(
					`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${str}</textarea>`,
					`<textarea id="${event.target.id}" class="text-area" placeholder="Ввести текст">${event.target.value}</textarea>`,
				);
			}
		} else if (
			event.target.value &&
			event.target.type !== "select-one" &&
			event.target.type !== "checkbox"
		) {
			if (event.target.id.includes("col") && event.target.type === "text") {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}"`,
				);
				setTableValue(event.target.id, event.target.value, null);
			} else if (
				event.target.id.includes("col") &&
				event.target.type === "date"
			) {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}"`,
				);
				setTableValue(event.target.id, event.target.value, null);
			} else if (
				event.target.id.includes("col") &&
				event.target.type === "number"
			) {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}"`,
				);
				setTableValue(event.target.id, event.target.value, null);
			} else if (
				event.target.id.includes("startcol") &&
				event.target.type === "time"
			) {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}"`,
				);
				setTableValue(event.target.id, event.target.value, "start");
			} else if (
				event.target.id.includes("endcol") &&
				event.target.type === "time"
			) {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}"`,
				);
				setTableValue(event.target.id, event.target.value, "end");
			} else {
				templateData.body = templateData.body.replace(
					`id="${event.target.id}"`,
					`id="${event.target.id}" value="${event.target.value}" autofocus`,
				);
			}
		} else if (
			event.target.value === "" &&
			event.target.type !== "select-one" &&
			event.target.type !== "checkbox"
		) {
			if (event.target.id.includes("col")) {
				const columnIndex = event.target.id.indexOf("l") + 1;
				const rowIndex = event.target.id.lastIndexOf("-");
				const column = event.target.id.substring(columnIndex, rowIndex);
				const row = event.target.id.substr(rowIndex + 1);
				const selectedData = tableData.find(
					data => data.number == row && data.columnNumber == column,
				);
				if (selectedData && selectedData.value) {
					if (event.target.id[0] == "c") {
						templateData.body = templateData.body.replace(
							`id="${event.target.id}" value="${selectedData.value}"`,
							`id="${event.target.id}"`,
						);
					} else {
						if (selectedData.value.length == 1) {
							templateData.body = templateData.body.replace(
								`id="${event.target.id}" value="${selectedData.value[0]}"`,
								`id="${event.target.id}"`,
							);
						} else {
							templateData.body = templateData.body.replace(
								`id="${event.target.id}" value="${
									selectedData.value[event.target.id[0]]
								}"`,
								`id="${event.target.id}"`,
							);
						}
					}
					setTableValue(event.target.id, "", null);
				}
			}
		}

		if (
			document.querySelector(".template-table-container") &&
			tableScrollLeft
		) {
			setTimeout(() => {
				document.querySelector(".template-table-container").scrollLeft =
					tableScrollLeft;
			});
		}
		setTemplateData(templateData);
	};

	const setInputsListValues = (id, value, placeholder) => {
		const index = +id.substr(id.lastIndexOf("-") + 1);

		if (value) {
			if (inputsListData[index]) {
				templateData.body = templateData.body.replace(
					`<input id="${id}" value="${inputsListData[index]}"`,
					`<input id="${id}" value="${value}"`,
				);
				if (templateData.body.includes("sign-list")) {
					templateData.body = templateData.body.replace(
						`<input disabled id="sign-list-${index}" value="${inputsListData[index]}"`,
						`<input disabled id="sign-list-${index}" value="${value}"`,
					);
				}
				inputsListData[index] = value;
			} else {
				inputsListData.push(value);
				templateData.body = templateData.body.replace(
					`<input id="inputs-list-${
						inputsListData.length - 1
					}" value="" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
					`<input id="inputs-list-${
						inputsListData.length - 1
					}" value="${value}" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${
						inputsListData.length
					}" value="" type="text" autocomplete="off" placeholder="${placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
				);
				if (templateData.body.includes("sign-list")) {
					templateData.body = templateData.body.replace(
						`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
							inputsListData.length - 1
						}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
						`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
							inputsListData.length - 1
						}" value="${value}" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div><div class="flexable"><input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height"><input disabled id="sign-list-${
							inputsListData.length
						}" value="" type="text" autocomplete="off" placeholder="Фамилия И.О." class="custom-input template-input bottom-short-mrg fit-height"></div>`,
					);
				}
			}
		} else if (inputsListData[index]) {
			templateData.body = templateData.body.replace(
				`<input id="${id}" value="${inputsListData[index]}"`,
				`<input id="${id}" value=""`,
			);
			if (templateData.body.includes("sign-list")) {
				templateData.body = templateData.body.replace(
					`<input disabled id="sign-list-${index}" value="${inputsListData[index]}"`,
					`<input id="sign-list-${index}" value=""`,
				);
			}
			inputsListData[index] = "";
		}
		setTemplateData(templateData);
	};

	return translationService ? (
		<div className="m-section document-form">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="content-title p-0">
							<Link
								to={`/${language}/${DOCUMENT_KEY}`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							{props.match.params.documentId
								? translationService.translate("TR_UPDATE_DOCUMENT")
								: translationService.translate("TR_CREATE_DOCUMENT")}
						</h2>
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<div className="form-group">
							<label htmlFor="">
								{translationService.translate("TR_DOCUMENT_TYPE")}
							</label>
							<ReactSelectOption
								value={documentTypeId}
								isDisabled={documentId}
								selectedValue={(() => {
									const selectedItem = {
										...documentType.find(data => data.id === documentTypeId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentType.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => onSelectOptionChange(item, "documentTypeId")}
							/>
						</div>
					</div>
					<div className="col-12 col-lg-4 col-md-6">
						<div className="form-group">
							<label htmlFor="">
								{translationService.translate("TR_DOCUMENT_TYPE")}
							</label>
							<ReactSelectOption
								value={documentTemplateId}
								isDisabled={documentId}
								selectedValue={(() => {
									const selectedItem = {
										...templates.find(data => data.id === documentTemplateId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={templates.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item =>
									onSelectOptionChange(item, "documentTemplateId")
								}
							/>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<div className="course-media-wrapper">
							<p className="my-2">
								{translationService.translate("TR_UPLOAD_FILE")}
							</p>
							{uploadedFile ? (
								<div className="upload-file-content">
									<div onClick={removeImg}>
										<CloseSvg />
									</div>
									<div
										className="img"
										style={{ backgroundImage: `url(${uploadedFile})` }}
										alt="course-image"></div>
								</div>
							) : null}
							{uploadedFilePath && !file ? (
								<div className="upload-file-content">
									<div
										className="img"
										style={{ backgroundImage: `url(${uploadedFilePath})` }}
										alt="course-image"></div>
								</div>
							) : null}
						</div>
						<div className="course-media-wrapper">
							<div className="form-group position-relative">
								<UploadButton
									textInputClasses="pl-100"
									text={translationService.translate("TR_UPLOAD_FILE")}
									clicked={uploadFile}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-lg-4 col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							inputClassName="pr--5"
							fieldLength={nameMaxLength}
							value={documentName}
							isInvalidSubmit={isInvalidSubmit}
							isInvalidField={isInvalidSubmit && !documentName.trim()}
							labelValue={`${translationService.translate(
								"TR_DOCUMENT_NAME",
							)} *`}
							onChange={event =>
								onChange(event, setDocumentName, nameMaxLength)
							}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<Textarea
							rows="2"
							id="description"
							name="description"
							value={description}
							max={descriptionMaxLength}
							labelValue={`${translationService.translate(
								"TR_DESCRIPTION_OR_NUMBER",
							)}`}
							onChange={event => {
								if (event.target.value.length <= descriptionMaxLength) {
									setDescription(event.target.value);
								} else {
									return;
								}
							}}
						/>
					</div>
				</div>
				<div
					ref={containerRef}
					dangerouslySetInnerHTML={{
						__html: templateData && templateData.body ? templateData.body : "",
					}}
					className="template-container"></div>
			</div>
			<div className="row">
				<div className="col-12">
					<ActionButton
						type="button"
						spinnerId={buttonSpinnerId}
						className="mindalay--btn-dark mb-4"
						name={
							documentId
								? translationService.translate("TR_UPDATE")
								: translationService.translate("TR_CREATE")
						}
						clicked={onSubmit}
					/>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(DocumentForm);

// import React, { useState, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import uuid from "react-uuid";
// import Input from "./../Components/Inputs/input";
// import ReactSelectOption from "./../Components/SelectOption/reactSelectOption";
// import {
// 	ERROR_KEY,
// 	SUCCESS_KEY,
// 	VALID_IMAGE_TYPES_KEY,
// } from "../Constants/mainKeys";
// import { DOCUMENT_KEY } from "../Constants/urlKeys";
// import AlertService from "../Services/alertService";
// import ApiService from "../Services/apiService";
// import TranslationService from "../Services/translationService";
// import {
// 	addButtonSpinner,
// 	addPartialViewSpinner,
// 	removeButtonSpinner,
// 	removePageSpinner,
// 	removePartialViewSpinner,
// } from "../Store/Actions/spinner";
// import ArrowBackSvg from "./../Components/Svg/arrowBackSvg";
// import CloseSvg from "./../Components/Svg/closeSvg";
// import UploadButton from "./../Components/UploadButton/uploadButton";
// import MainService from "./../Services/mainService";
// import Textarea from "./../Components/Inputs/textArea";
// import ActionButton from "./../Components/ActionButton/actionButton";
// import "./../documentsForm.css";

// const buttonSpinnerId = uuid();

// const DocumentForm = props => {
// 	const containerRef = useRef(null);
// 	const [documentType, setDocumentType] = useState([]);
// 	const [templates, setTemplates] = useState([]);
// 	const [template, setTemplate] = useState("");
// 	const [templateInfo, setTemplateInfo] = useState(null);
// 	const [templateData, setTemplateData] = useState("");
// 	const { documentId } = props.match.params;
// 	const nameMaxLength = 120;
// 	const descriptionMaxLength = 250;
// 	const mainService = new MainService();
// 	const dispatch = useDispatch();
// 	const translations = useSelector(state => state.translation.translations);
// 	const language = useSelector(state => state.language.language);
// 	const user = useSelector(state => state.user.user);
// 	const [documentTypeId, setDocumentTypeId] = useState(null);
// 	const [documentTemplateId, setDocumentTemplateId] = useState(null);
// 	const [documentCategoryId, setDocumentCategoryId] = useState(2);
// 	const [translationService, setTranslationService] = useState(null);
// 	const [documentName, setDocumentName] = useState("");
// 	const [documentBodyData, setDocumentBodyData] = useState(null);
// 	const [tableData, setTableData] = useState([]);
// 	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);
// 	const [description, setDescription] = useState("");
// 	const [tableRowCount, setTableRowCount] = useState(1);
// 	const [uploadedFilePath, setUploadedFilePath] = useState(null);
// 	const [tableTds, setTableTds] = useState([]);
// 	const [uploadedFile, setUploadedFile] = useState(null);
// 	const [file, setFile] = useState(null);
// 	const [tableListDates, setTableListDates] = useState({});
// 	// const [groupName, setGroupName] = useState("");
// 	// const [organizationName, setOrganizationName] = useState("");
// 	const [tableLibDatas, setTableLibDatas] = useState({});
// 	const [groupData, setGroupData] = useState([]);
// 	const [inputsListData, setInputsListData] = useState([]);
// 	const [students, setStudents] = useState([]);
// 	const [studentsList, setStudentsList] = useState("");
// 	const [values, setValues] = useState({
// 		a1: "",
// 		a2: "",
// 		a3: "",
// 		a4: "",
// 		a5: "",
// 		a6: "",
// 		a7: "",
// 		a8: "",
// 		a9: "",
// 		a10: "",
// 		a11: "",
// 		a12: "",
// 		a13: "",
// 		a14: "",
// 		a15: "",
// 		a16: "",
// 		a17: "",
// 		a18: "",
// 		a19: "",
// 		a20: "",
// 		a21: "",
// 		a22: "",
// 		a23: "",
// 		a24: "",
// 		a25: "",
// 		a26: "",
// 		a27: "",
// 		a28: "",
// 		a29: "",
// 		a30: "",
// 		a31: "",
// 		a32: "",
// 		a33: "",
// 		tableData: [],
// 	});

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	useEffect(() => {
// 		getDocumentCategory();
// 	}, []);

// 	useEffect(() => {
// 		if (documentId) {
// 			getDocumentById(documentId);
// 		}
// 	}, [documentId]);

// 	useEffect(() => {
// 		if (containerRef.current) {
// 			setTimeout(() => {
// 				containerRef.current.addEventListener("click", buttonClick);
// 			}, 1);
// 		}

// 		return () => {
// 			if (containerRef.current) {
// 				containerRef.current.removeEventListener("click", buttonClick);
// 			}
// 		};
// 	}, [template, containerRef]);

// 	useEffect(() => {
// 		if (containerRef) {
// 			setTimeout(() => {
// 				attachEventListeners();
// 			}, 1);
// 		}
// 	}, [template, containerRef]);

// 	const attachEventListeners = () => {
// 		if (containerRef && containerRef.current) {
// 			const inputs = containerRef.current.querySelectorAll(
// 				"input, select, checkbox",
// 			);
// 			inputs.forEach(input => {
// 				input.addEventListener("change", event => {
// 					changeTemplateValue(event);
// 				});
// 			});
// 		}
// 	};

// 	const buttonClick = event => {
// 		if (event.target.type !== "button") {
// 			return;
// 		}
// 		if (event.target.id.includes("add")) {
// 			addTableRow();
// 		} else if (event.target.id.includes("remove")) {
// 			const row = event.target.id.substring(
// 				event.target.id.lastIndexOf("-") + 1,
// 			);
// 			removeTableRow(row);
// 		}
// 	};

// 	const removeTableRow = row => {
// 		const regex = new RegExp(`<tr id="row-${row}".*?</tr>`, "gs");
// 		templateData.body = templateData.body.replace(regex, "");

// 		const newTableData = tableData.filter(
// 			data => data.number !== row.toString(),
// 		);
// 		setTableData(newTableData);
// 		if (tableRowCount === 1) {
// 		} else {
// 			setTableRowCount(tableRowCount - 1);
// 		}

// 		setTemplateData(templateData);

// 		return new Promise((res, rej) => {
// 			res({ row, templateData });
// 		});
// 	};

// 	const addTableRow = () => {
// 		const newRow = `<tr id="row-${tableRowCount + 1}"><td>${
// 			tableRowCount + 1
// 		}</td>${tableTds
// 			.map(tdItem =>
// 				generateTableElement(
// 					getTemplateInfo(extractPositionNumber(tdItem)),
// 					tableRowCount + 1,
// 				),
// 			)
// 			.join("")}</tr>`;
// 		template = template.replace("</tbody>", `${newRow}</tbody>`);

// 		setTableRowCount(tableRowCount + 1);
// 		setTemplate(template);

// 		return new Promise((res, rej) => {
// 			res({ rowCount: 0, template });
// 		});
// 	};
// 	const getTemplateInfo = positionNumber => {
// 		return templateInfo.find(
// 			data => data.position.toString() === positionNumber,
// 		);
// 	};

// 	const extractPositionNumber = tdItem => {
// 		const firstIndex = tdItem.indexOf("{") + 2;
// 		const lastIndex = tdItem.indexOf("}");
// 		return tdItem.substring(firstIndex, lastIndex);
// 	};
// 	const generateTableElement = (templateInfo, rowCount) => {
// 		const position = templateInfo.position;
// 		switch (templateInfo.type) {
// 			case "table-input-string":
// 				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="text" autocomplete="off" placeholder="${
// 					templateInfo.placeholder || ""
// 				}" class="custom-input template-table-input"></td>`;

// 			case "table-input-date":
// 				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input"></td>`;

// 			case "table-input-number":
// 				return `<td><input id="col${position}-${rowCount}" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input"></td>`;

// 			case "table-number-and-date":
// 				return `<td>№<input id="0-col${position}-${rowCount}" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input"> от <input id="1-col${position}-${rowCount}" name="a${position}" type="date" autocomplete="off" class="custom-input date-input"></td>`;

// 			case "table-input-start-end-time":
// 				return `<td><div class="flexable"><input id="startcol${position}-${rowCount}" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${position}-${rowCount}" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"></div></td>`;

// 			case "table-library":
// 			case "table-list":
// 				const dropdownData =
// 					templateInfo.type === "table-library"
// 						? tableLibDatas[position]
// 						: tableListDates[position];
// 				let dropdown = setDropDownValue(dropdownData, position, rowCount);
// 				return `<td>${dropdown.replace(
// 					`id="${position}"`,
// 					`id="col${position}-${rowCount}"`,
// 				)}</td>`;

// 			case "table-sign-part":
// 				return `<td><div id="col${position}-${rowCount}"></div></td>`;

// 			case "remove-row-button":
// 				return `<td><button id="remove-${position}-${rowCount}" type="button" class="table-row-add">Remove</button></td>`;

// 			default:
// 				return "<td></td>"; // Если тип не поддерживается, возвращаем пустую ячейку
// 		}
// 	};

// 	const getDocumentById = id => {
// 		if (!id) return false;
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getDocumentById(id)
// 			.then(response => {
// 				if (response && response.data) {
// 					const spinnerId = uuid();
// 					ApiService.getDocumentTemplatesByCategoryId(
// 						response.data.documentCategoryId,
// 					)
// 						.then(_response => {
// 							if (_response && _response.data) {
// 								setTemplates(_response.data);
// 								let templates = _response.data;

// 								setDocumentTypeId(response.data.documentCategoryId);
// 								ApiService.getDocumentTemplateInfoInTemplateId(
// 									response.data.documentCategoryId,
// 								)
// 									.then(res => {
// 										if (res && res.data) {
// 											setDocumentTemplateId(response.data.documentTemplateId);
// 											let selectedItem = _response.data.find(
// 												item => item.id === response.data.documentTemplateId,
// 											);
// 											setTemplateData(selectedItem);
// 											setTemplateInfo(res.data);
// 											setTemplateInfoData(
// 												res.data,
// 												selectedItem,
// 												response.data.data,
// 											);
// 										}
// 									})
// 									.catch(error =>
// 										AlertService.alert(
// 											AlertService.checkMessageType(error.respcode) ||
// 												ERROR_KEY,
// 											error,
// 										),
// 									)
// 									.finally(() => {});
// 							}
// 						})
// 						.catch(error =>
// 							AlertService.alert(
// 								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 								error,
// 							),
// 						)
// 						.finally(() => {});
// 				}
// 			})
// 			.catch(error =>
// 				AlertService.alert(
// 					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 					error,
// 				),
// 			)
// 			.finally(() => {
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			});
// 	};

// 	const getDocumentCategory = () => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getDocumentCategory()
// 			.then(response => {
// 				if (response && response.data) {
// 					setDocumentType(response.data);
// 				}
// 			})
// 			.catch(error =>
// 				AlertService.alert(
// 					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 					error,
// 				),
// 			)
// 			.finally(() => {
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			});
// 	};

// 	const onSubmit = () => {
// 		var stateCopy = { ...values };
// 		if (!documentName || (documentName && !documentName.trim().length)) {
// 			setIsInvalidSubmit(true);
// 			window.scrollTo({ top: 0, behavior: "smooth" });
// 			return;
// 		}
// 		if (documentTypeId === 2) {
// 			stateCopy.tableData = [];
// 		} else {
// 			stateCopy.tableData.forEach(tableDataItem => {
// 				tableDataItem.number = tableDataItem.number.toString();
// 				tableDataItem.columnNumber = tableDataItem.columnNumber.toString();
// 			});
// 		}
// 		const formData = new FormData();
// 		dispatch(addButtonSpinner(buttonSpinnerId));

// 		const form = {
// 			file,
// 			description: description.trim(),
// 			documentName: documentName.trim(),
// 		};

// 		if (file) {
// 			formData.append("documentFile", form.file);
// 		}

// 		!uploadedFilePath && formData.append("data", JSON.stringify(stateCopy));

// 		formData.append("documentTemplateId", documentTemplateId);

// 		// !uploadedFilePath && formData.append("documentCategoryId", documentCategoryId);
// 		formData.append("documentCategoryId", documentTypeId);
// 		form.documentName && formData.append("name", form.documentName);
// 		form.description && formData.append("description", form.description);
// 		documentId && formData.append("id", documentId);

// 		(documentId
// 			? ApiService.updateDocument(formData)
// 			: ApiService.createDocument(formData)
// 		)
// 			.then(response => {
// 				dispatch(removeButtonSpinner(buttonSpinnerId));
// 				AlertService.alert(
// 					SUCCESS_KEY,
// 					translationService.translate(
// 						documentId
// 							? "TR_DOCUMENT_SUCCESSFULLY_UPDATED"
// 							: "TR_DOCUMENT_SUCCESSFULLY_CREATED",
// 					),
// 				);
// 				props.history.push(`/${language}/${DOCUMENT_KEY}`);
// 			})
// 			.catch(error => getFail(error, buttonSpinnerId));
// 	};

// 	const getDivisionsStudents = (id, html) => {
// 		ApiService.getDivisionsStudents(id, false)
// 			.then(response => {
// 				if (response && response.data && response.data.length) {
// 				}
// 			})
// 			.catch(error => {
// 				error &&
// 					AlertService.alert(
// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 						error,
// 					);
// 			});
// 	};

// 	const getFail = (error, spinnerId) => {
// 		error &&
// 			AlertService.alert(
// 				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 				error,
// 			);
// 		spinnerId && dispatch(removeButtonSpinner(spinnerId));
// 		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
// 		props.history.push(`/${language}/${DOCUMENT_KEY}`);
// 	};

// 	const onSelectOptionChange = (selectedItem, fieldName) => {
// 		if (fieldName === "documentTypeId") {
// 			const spinnerId = uuid();
// 			// dispatch(addPartialViewSpinner(spinnerId));
// 			ApiService.getDocumentTemplatesByCategoryId(selectedItem.id)
// 				.then(response => {
// 					if (response && response.data) {
// 						setTemplates(response.data);
// 						setDocumentTypeId(selectedItem.id);

// 						// const documentData = JSON.parse(selectedItem.body);
// 					}
// 				})
// 				.catch(error =>
// 					AlertService.alert(
// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 						error,
// 					),
// 				)
// 				.finally(() => {
// 					// dispatch(removePartialViewSpinner(spinnerId));
// 				});
// 		}
// 		if (fieldName === "documentTemplateId") {
// 			const spinnerId = uuid();
// 			// dispatch(addPartialViewSpinner(spinnerId));
// 			ApiService.getDocumentTemplateInfoInTemplateId(selectedItem.id)
// 				.then(response => {
// 					if (response && response.data) {
// 						setDocumentTemplateId(selectedItem.id);
// 						setTemplateData(selectedItem);
// 						setTemplateInfo(response.data);
// 						setTemplateInfoData(response.data, selectedItem);
// 					}
// 				})
// 				.catch(error =>
// 					AlertService.alert(
// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 						error,
// 					),
// 				)
// 				.finally(() => {
// 					// dispatch(removePartialViewSpinner(spinnerId));
// 				});
// 		}
// 	};

// 	const setTemplateInfoData = (
// 		templateInfo,
// 		templateData,
// 		documentBodyData = null,
// 	) => {
// 		const body = templateData.body;
// 		let documentData = null;
// 		if (documentBodyData) {
// 			documentData = { ...values, ...JSON.parse(documentBodyData) };
// 			setValues(values => ({ ...values, ...documentData }));
// 		} else {
// 			documentData = values;
// 		}

// 		let tableListDatasObs, tableColumnCount;

// 		templateInfo.forEach((data, index) => {
// 			const position = data.position;
// 			const type = data.type;

// 			if (
// 				data.type === "table-input-string" ||
// 				data.type === "table-input-date" ||
// 				data.type === "table-input-number" ||
// 				data.type === "table-library" ||
// 				data.type === "table-list" ||
// 				data.type === "table-sign-part" ||
// 				data.type === "table-number-and-date" ||
// 				data.type === "table-input-start-end-time" ||
// 				data.type === "remove-row-button"
// 			) {
// 				setTableTds(tds => [...tds, `<td>{a${data.position}}</td>`]);
// 				if (documentData.tableData.length) {
// 					templateData.body = templateData.body
// 						.replace(
// 							`<td>{a${data.position}}</td>`,
// 							`<td class="col${data.position}-${documentData.tableData[0].number}">{a${data.position}}</td>`,
// 						)
// 						.replace(
// 							`<td>№ {a${data.position}}</td>`,
// 							`<td class="col${data.position}-${documentData.tableData[0].number}">№ {a${data.position}}</td>`,
// 						);
// 				}
// 			}
// 			switch (type) {
// 				case "organization-name":
// 					documentData[`a${position}`] = user.organizationName;
// 					templateData.body = templateData.body.replace(
// 						`<p>{a${position}}</p>`,
// 						`<input id="a${position}" name="a${position}" data-type="${
// 							data.type
// 						}" autocomplete="off" class="custom-input organization-title center"  value="${
// 							documentData[`a${position}`]
// 						}" >`,
// 					);
// 				case "input-create-date":
// 					templateData.body = templateData.body.replace(
// 						`<p>{a${position}}</p>`,
// 						`<input id="a${position}" name="a${position}" data-type="${data.type}" type="date" autocomplete="off" class="custom-input date-input">`,
// 					);
// 					break;
// 				case "input-date":
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<input id="a${position}" name="a${position}" data-type="${data.type}" type="date" autocomplete="off" class="custom-input date-input bottom-short-mrg">`,
// 					);
// 					break;
// 				case "input-number":
// 					templateData.body = templateData.body.replace(
// 						`<p>№ {a${position}}</p>`,
// 						`<div class="direction-right"><span class="number-label">№</span><input id="a${position}" data-type="${data.type}" name="a${position}" type="number" autocomplete="off" class="custom-input doc-num"  value="this.value = Math.round(this.value)"></div>`,
// 					);
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<input id="a${position}" name="a${position}" type="number" data-type="${data.type}" autocomplete="off" class="custom-input doc-num">`,
// 					);
// 					break;
// 				case "library":
// 					ApiService.getLibrarydataByLibId(data.entityId)
// 						.then(response => {
// 							let str = setDropDownValue(
// 								response.data,
// 								position,
// 								null,
// 								values[`a${position}`],
// 							);
// 							templateData.body = templateData.body.replace(
// 								`{a${position}}`,
// 								str,
// 							);
// 						})
// 						.catch(error => {
// 							AlertService.alert(
// 								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 								error,
// 							);
// 						});
// 					break;
// 				case "input-string":
// 					if (!data.placeholder) {
// 						data.placeholder = "";
// 					}
// 					templateData.body = templateData.body.replace(
// 						`<h2>{a${position}}</h2>`,
// 						`<h2 class="center selected-font"><input id="a${position}" data-type="${data.type}"  name="a${position}" onchange="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg"></h2>`,
// 					);
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<input id="a${position}" name="a${position}" type="text" data-type="${data.type}"  autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height">`,
// 					);
// 					break;
// 				case "inputs-list":
// 					if (documentData && documentBodyData) {
// 						const inputsData = documentData[`a${position}`];
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="${inputsData[0]}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 						);
// 						for (let i = 0; i < inputsData.length; i++) {
// 							if (!i) {
// 								continue;
// 							}
// 							templateData.body = templateData.body.replace(
// 								`<input id="inputs-list-${i - 1}" value="${
// 									inputsData[i - 1]
// 								}" type="text" autocomplete="off" placeholder="${
// 									data.placeholder
// 								}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 								`<input id="inputs-list-${i - 1}" value="${
// 									inputsData[i - 1]
// 								}" type="text" autocomplete="off" placeholder="${
// 									data.placeholder
// 								}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${i}" value="${
// 									inputsData[i]
// 								}" type="text" autocomplete="off" placeholder="${
// 									data.placeholder
// 								}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 							);
// 						}
// 						templateData.body = templateData.body.replace(
// 							`<input id="inputs-list-${inputsData.length - 1}" value="${
// 								inputsData[inputsData.length - 1]
// 							}" type="text" autocomplete="off" placeholder="${
// 								data.placeholder
// 							}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 							`<input id="inputs-list-${inputsData.length - 1}" value="${
// 								inputsData[inputsData.length - 1]
// 							}" type="text" autocomplete="off" placeholder="${
// 								data.placeholder
// 							}" class="custom-input template-input bottom-short-mrg fit-height"><input id="inputs-list-${
// 								inputsData.length
// 							}" value="" type="text" autocomplete="off" placeholder="${
// 								data.placeholder
// 							}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 						);
// 						setInputsListData(inputsData);
// 					} else {
// 						if (!data.placeholder) {
// 							data.placeholder = "";
// 						}
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<div id="a${position}" class="users-input-list"><input id="inputs-list-0" value="" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-input bottom-short-mrg fit-height"></div>`,
// 						);
// 					}
// 					break;
// 				case "sign-row":
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<input disabled type="text" autocomplete="off" class="custom-input max-assignment-width fit-height">`,
// 					);

// 					break;
// 				case "drop-down-group":
// 				case "drop-down-group-not-draw":
// 					ApiService.getAllStudentDivisions()
// 						.then(response => {
// 							let groupData = response.data;
// 							setGroupData(groupData);

// 							let str = setDropDownValue(groupData, position, null, null, true);
// 							templateData.body = templateData.body.replace(
// 								`{a${position}}`,
// 								str,
// 							);
// 							for (let i in documentData) {
// 								if (
// 									documentData[i] &&
// 									groupData.find(
// 										data =>
// 											data.groupName === documentData[i] ||
// 											data.name === documentData[i],
// 									)
// 								) {
// 									templateData.body = templateData.body.replace(
// 										`>${documentData[i]}`,
// 										`selected >${documentData[i]}`,
// 									);
// 								}
// 							}
// 							const selectedTemplateInfo = templateInfo.find(
// 								data => data.position === position + 1,
// 							);
// 							if (
// 								documentBodyData &&
// 								documentData &&
// 								selectedTemplateInfo.type === "students-list"
// 							) {
// 								const studentsData = documentData[`a${position + 1}`];
// 								const selectedGroup = groupData.find(
// 									group => group.groupName === documentData[`a${position}`],
// 								);
// 								if (selectedGroup) {
// 									// getGroupStudents(
// 									// 	selectedGroup.id,
// 									// 	`a${position}`,
// 									// 	studentsData,
// 									// );
// 								}
// 							}
// 						})
// 						.catch(error => {
// 							AlertService.alert(
// 								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 								error,
// 							);
// 						});
// 					break;
// 				case "list":
// 					let str = "";
// 					ApiService.getDocumentTemplateInfoData(data.id)
// 						.then(response => {
// 							let listData = response.data;
// 							str = setDropDownValue(listData, position, null);
// 						})
// 						.catch(error => {
// 							AlertService.alert(
// 								AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 								error,
// 							);
// 						});
// 					templateData.body = templateData.body.replace(`{a${position}}`, str);
// 					break;
// 				case "input-long-string":
// 					if (!data.placeholder) {
// 						data.placeholder = "";
// 					}
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<input id="a${position}" name="a${position}" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input fit-height">`,
// 					);
// 					break;
// 				case "text-area":
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<textarea id="a${position}" class="text-area" placeholder="Ввести текст"></textarea>`,
// 					);
// 					break;
// 				case "add-row-button":
// 					templateData.body = templateData.body.replace(
// 						`{a${position}}`,
// 						`<button id="add-${position}" type="button" class="table-row-add">Add</button>`,
// 					);
// 					break;
// 				case "table-input-string":
// 					if (!documentData.tableData.length) {
// 						if (!data.placeholder) {
// 							data.placeholder = "";
// 						}
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<input id="col${position}-1" name="a${position}" data-type="table" type="text" autocomplete="off" placeholder="${data.placeholder}" class="custom-input template-table-input">`,
// 						);
// 					}
// 					break;
// 				case "table-input-date":
// 					if (!documentData.tableData.length) {
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<input id="col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`,
// 						);
// 					}
// 					break;
// 				case "table-input-number":
// 					if (!documentData.tableData.length) {
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<input id="col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">`,
// 						);
// 					}
// 					break;
// 				case "table-library":
// 					if (!documentData.tableData.length) {
// 						ApiService.getLibrarydataByLibId(data.entityId);
// 						let str = ""
// 							.then(response => {
// 								const libraryData = response.data;
// 								tableLibDatas[position] = libraryData;
// 								setTableLibDatas(tableLibDatas);
// 								str = setDropDownValue(libraryData, position, 1);
// 								str = str.replace(`id="${position}"`, `id="col${position}-1"`);
// 								templateData.body = templateData.body.replace(
// 									`{a${position}}`,
// 									str,
// 								);
// 							})
// 							.catch(error => {
// 								AlertService.alert(
// 									AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 									error,
// 								);
// 							});
// 					}
// 					break;
// 				case "table-list":
// 					if (!documentData.tableData.length) {
// 						ApiService.getDocumentTemplateInfoData(data.id)
// 							.then(response => {
// 								const tableListData = response.data;
// 								tableListDates[position] = tableListData;
// 								setTableListDates(tableListDates);
// 								let str = setDropDownValue(
// 									tableListDates[position],
// 									position,
// 									1,
// 								);
// 								str = str.replace(`id="${position}"`, `id="col${position}-1"`);
// 								templateData.body = templateData.body.replace(
// 									`{a${position}}`,
// 									str,
// 								);
// 							})
// 							.catch(error => {
// 								AlertService.alert(
// 									AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 									error,
// 								);
// 							});
// 					}
// 					break;
// 				case "table-sign-part":
// 					templateData.body = templateData.body.replace(
// 						`{a${data.position}}`,
// 						`<div id="col${position}-1"></div>`,
// 					);

// 					break;
// 				case "table-number-and-date":
// 					if (!documentData.tableData.length) {
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`№<input id="0-col${position}-1" name="a${position}" type="number" autocomplete="off" class="custom-input template-table-input">от<input id="1-col${position}-1" name="a${position}" type="date" autocomplete="off" class="custom-input date-input">`,
// 						);
// 					}
// 					break;
// 				case "table-input-start-end-time":
// 					if (!documentData.tableData.length) {
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<div class="flexable"><input id="startcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"> - <input id="endcol${position}-1" name="a${position}" type="time" autocomplete="off" class="custom-input compact-width"></div>`,
// 						);
// 					}
// 					break;
// 				case "remove-row-button":
// 					if (!documentData.tableData.length) {
// 						templateData.body = templateData.body.replace(
// 							`{a${position}}`,
// 							`<button id="remove-${position}-1" type="button" class="table-row-add">Remove</button>`,
// 						);
// 					}
// 					break;
// 				default:
// 					break;
// 			}
// 			if (data.hidden) {
// 				if (documentBodyData && documentData && data.type === "students-list") {
// 					const studentsData = documentData[`a${position}`];
// 				}
// 				if (templateInfo[index - 1].entityId === 5) {
// 					templateData.body = templateData.body.replace(
// 						`id="a${position}"`,
// 						`id="entity-a${position}" class="hidden"`,
// 					);
// 				} else {
// 					templateData.body = templateData.body.replace(
// 						`id="a${position}"`,
// 						`id="a${position}" class="hidden"`,
// 					);
// 					templateData.body = templateData.body.replace(
// 						`<p>{a${position}}</p>`,
// 						`<p id="a${position}" class="hidden">{a${position}}</p>`,
// 					);
// 				}
// 			}
// 		});
// 		if (documentData.tableData?.length) {
// 			let { tableData } = documentData;
// 			let templateBody = templateData.body
// 				.replace("<tr>", `<tr class="row-th">`)
// 				.replace("<tr>", `<tr class="row-${tableData[0].number}">`);

// 			const replaceTemplateBody = (infoData, data, inputType, value = "") => {
// 				const classAttr = `col${infoData.position}-${data.number}`;
// 				templateBody = templateBody.replace(
// 					`<td class="${classAttr}">{a${infoData.position}}</td>`,
// 					`<td class="${classAttr}"><input id="col${infoData.position}-${data.number}" value="${value}" name="a${infoData.position}" type="${inputType}" autocomplete="off" class="custom-input"></td>`,
// 				);
// 			};

// 			tableData.forEach((data, index) => {
// 				if (!templateBody.includes(`<tr class="row-${data.number}">`)) {
// 					tableRowCount++;
// 					let row = `<tr class="row-${data.number}"><td>${tableRowCount}</td></tr>`;

// 					tableTds.forEach(tdItem => {
// 						const column = tdItem.substring(
// 							tdItem.indexOf("{a") + 2,
// 							tdItem.indexOf("}"),
// 						);
// 						tdItem = tdItem.replace(
// 							"<td>",
// 							`<td class="col${column}-${data.number}">`,
// 						);
// 						row = row.replace("</tr>", `${tdItem}</tr>`);
// 					});

// 					templateBody = templateBody.replace("</tbody>", `${row}</tbody>`);
// 					setTableRowCount(tableRowCount);
// 				}

// 				const infoData = templateInfo.find(
// 					info => info.position.toString() === data.columnNumber,
// 				);

// 				if (infoData?.type === "table-number-and-date") {
// 					const [numberValue, dateValue] = data.value || [];
// 					templateBody = templateBody.replace(
// 						`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`,
// 						`№<input id="0-col${infoData.position}-${data.number}" value="${
// 							numberValue || ""
// 						}" type="number"> от <input id="1-col${infoData.position}-${
// 							data.number
// 						}" value="${dateValue || ""}" type="date">`,
// 					);
// 				} else if (infoData?.type === "table-input-number") {
// 					replaceTemplateBody(infoData, data, "number", data.value);
// 				} else if (infoData?.type === "table-input-date") {
// 					replaceTemplateBody(infoData, data, "date", data.value);
// 				} else if (data.timeType === "start") {
// 					const endValue = tableData.find(
// 						item =>
// 							item.timeType === "end" &&
// 							item.columnNumber === infoData.position &&
// 							item.number === data.number,
// 					)?.value;
// 					templateBody = templateBody.replace(
// 						`<td class="col${infoData.position}-${data.number}">{a${infoData.position}}</td>`,
// 						`<div class="flexable"><input id="startcol${infoData.position}-${
// 							data.number
// 						}" value="${data.value}" type="time"> - <input id="endcol${
// 							infoData.position
// 						}-${data.number}" value="${endValue || ""}" type="time"></div>`,
// 					);
// 				} else if (infoData?.type === "table-input-string") {
// 					const placeholder = infoData.placeholder || "";
// 					replaceTemplateBody(infoData, data, "text", data.value);
// 				}
// 			});

// 			templateInfo.forEach(info => {
// 				if (["table-library", "table-list"].includes(info.type)) {
// 					const serviceCall =
// 						info.type === "table-library"
// 							? ApiService.getLibrarydataByLibId(info.entityId)
// 							: ApiService.getDocumentTemplateInfoData(info.id);

// 					serviceCall.then(response => {
// 						if (info.type === "table-library") {
// 							tableLibDatas[info.position] = response.data;
// 							setTableLibDatas(tableLibDatas);
// 						} else {
// 							tableListDates[info.position] = response;
// 							tableListDatasObs = tableListDates;
// 						}
// 					});
// 				}
// 			});

// 			templateData.body = templateBody;
// 		}

// 		if (documentData) {
// 			Object.keys(documentData).forEach(i => {
// 				if (documentData[i]) {
// 					// Замена для input с id "entity-{i}"
// 					templateData.body = templateData.body.replace(
// 						new RegExp(`<input id="entity-${i}" class="hidden"`, "g"),
// 						`<input id="entity-${i}" value="${documentData[i]}"`,
// 					);

// 					// Замена для input с id "{i}"
// 					templateData.body = templateData.body.replace(
// 						new RegExp(`<input id="${i}"`, "g"),
// 						`<input id="${i}" value="${documentData[i]}"`,
// 					);

// 					// Замена для textarea с id "{i}"
// 					templateData.body = templateData.body.replace(
// 						new RegExp(
// 							`<textarea id="${i}" class="text-area" placeholder="Ввести текст"></textarea>`,
// 							"g",
// 						),
// 						`<textarea id="${i}" class="text-area" placeholder="Ввести текст">${documentData[i]}</textarea>`,
// 					);
// 				}
// 			});
// 		}
// 		templateData.body = templateData.body.replace(
// 			"<table",
// 			'<div class="template-table-container"><table class="template-table"',
// 		);
// 		templateData.body = templateData.body.replace("</table>", "</table></div>");
// 		templateData.body = templateData.body.replace(
// 			"<h1>",
// 			'<h1 class="center template-title">',
// 		);
// 		templateData.body = templateData.body.replace(
// 			"<h3>",
// 			'<h3 class="center template-title margin-h3">',
// 		);
// 		templateData.body = templateData.body.replace(
// 			"<h3>",
// 			'<h3 class="doc-form-sub-title">',
// 		);
// 		templateData.body = templateData.body.replace(
// 			'<h3 class="center template-title margin-h3"><strong>',
// 			'<h3 class="temp-sub-title"><strong>',
// 		);
// 		templateData.body = templateData.body.replace(
// 			"<h2>",
// 			'<h2 class="temp-sub-title center selected-font">',
// 		);

// 		templateData.body = templateData.body
// 			.split("<p>")
// 			.join('<p class="flex-wrap">');
// 		setTemplate(templateData.body);
// 		setTemplateData(templateData);
// 	};

// 	const changeTemplateValue = event => {
// 		const fieldName = event.target.name;
// 		const newValue = event.target.value;

// 		setValues(values => ({
// 			...values,
// 			[fieldName]: newValue,
// 		}));

// 		const inputElement = containerRef.current.querySelector(
// 			`[name="${fieldName}"]`,
// 		);

// 		if (inputElement) {
// 			if (inputElement.className.includes("select-list")) {
// 				if (inputElement.getAttribute("data-type") === "isStudent") {
// 					const item = groupData.find(el => el.name === newValue);
// 					if (item && item.id) {
// 						getDivisionsStudents(item.id, containerRef.current);
// 					}
// 				}
// 				inputElement.value = newValue;
// 				[...inputElement.options].forEach(option => {
// 					option.selected = option.value === newValue;
// 				});
// 			} else {
// 				inputElement.value = newValue;
// 			}
// 		} else {
// 		}

// 		console.log(containerRef.current.innerHTML, values, "innerHTML");

// 		setTemplateData(prevData => ({
// 			...prevData,
// 			body: containerRef.current.innerHTML,
// 		}));
// 	};

// 	const setDropDownValue = (arr, ind, row, value, isStudent) => {
// 		if (!arr) {
// 			return;
// 		}
// 		if (!arr.length) {
// 			return `<select disabled id="a${ind}" name="a${ind}" class="select-list"></select>`;
// 		}
// 		let str = "";
// 		if (row) {
// 			if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
// 				str = `<select id="col${ind}-${row}"  class="select-list"><option selected disabled hidden></option><option selected id="col${ind}-${row}-0">${
// 					arr[0].name || arr[0].groupName || arr[0].data
// 				}</option></select>`;
// 			} else {
// 				str = `<select id="col${ind}-${row}" class="select-list"><option selected disabled hidden></option><option id="col${ind}-${row}-0">${
// 					arr[0].name || arr[0].groupName || arr[0].data
// 				}</option></select>`;
// 			}
// 			arr.forEach((item, i) => {
// 				str = str = str.replace(
// 					"</option></select>",
// 					`</option><option ${
// 						value &&
// 						value ===
// 							(arr[i].name || arr[i].groupName || arr[i].data ? "selected" : "")
// 					}  id="col${ind}-${row}-${i}">${
// 						item.name || item.groupName || item.data
// 					}</option></select>`,
// 				);
// 			});
// 		} else {
// 			if (value && value === (arr[0].name || arr[0].groupName || arr[0].data)) {
// 				// <option selected id="${ind}-0">
// 				// 	${arr[0].name || arr[0].groupName || arr[0].data}
// 				// </option>;
// 				str = `<select id="a${ind}" data-type="isStudent" name="a${ind}" class="select-list"><option selected disabled hidden></option></select>`;
// 			} else {
// 				// <option id="${ind}-0">
// 				// 	${arr[0].name || arr[0].groupName || arr[0].data}
// 				// </option>;
// 				str = `<select id="a${ind}" data-type="isStudent" name="a${ind}" class="select-list"><option selected disabled hidden></option></select>`;
// 			}
// 			arr.forEach((item, i) => {
// 				str = str = str.replace(
// 					"</option></select>",
// 					`</option><option value="${
// 						item.name || item.groupName || item.data
// 					}" ${
// 						value &&
// 						value ===
// 							(item.name || item.groupName || item.data ? "selected" : "")
// 					}  id="col${ind}-${row}-${i}">${
// 						item.name || item.groupName || item.data
// 					}</option></select>`,
// 				);
// 			});
// 		}
// 		return str;
// 	};

// 	const removeImg = () => {
// 		setUploadedFile(null);
// 		setFile(null);
// 	};

// 	const uploadFile = event => {
// 		const file = event.target.files[0];
// 		if (file) {
// 			mainService
// 				.readFile(file, VALID_IMAGE_TYPES_KEY)
// 				.then(uploadedFile => {
// 					setUploadedFile(uploadedFile);
// 					setFile(file);
// 				})
// 				.catch(
// 					error =>
// 						error &&
// 						AlertService.alert(
// 							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 							translationService.translate("TR_INVALID_FILE_FORMAT_EXC"),
// 						),
// 				);
// 		}
// 	};

// 	const onChange = (event, cb, maxLength = null) => {
// 		if (maxLength && maxLength < event.target.value.length) {
// 			return;
// 		}
// 		setIsInvalidSubmit(false);
// 		cb(event.target.value);
// 	};

// 	return translationService ? (
// 		<div className="m-section">
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-12">
// 						<h2 className="content-title p-0">
// 							<Link
// 								to={`/${language}/${DOCUMENT_KEY}`}
// 								title={translationService.translate("TR_BACK")}>
// 								<ArrowBackSvg />
// 							</Link>
// 							{props.match.params.documentId
// 								? translationService.translate("TR_UPDATE_DOCUMENT")
// 								: translationService.translate("TR_CREATE_DOCUMENT")}
// 						</h2>
// 						<hr />
// 					</div>
// 				</div>
// 				<div className="row">
// 					<div className="col-12 col-lg-4 col-md-6">
// 						<div className="form-group">
// 							<label htmlFor="">
// 								{translationService.translate("TR_DOCUMENT_TYPE")}
// 							</label>
// 							<ReactSelectOption
// 								value={documentTypeId}
// 								isDisabled={documentId}
// 								selectedValue={(() => {
// 									const selectedItem = {
// 										...documentType.find(data => data.id === documentTypeId),
// 									};
// 									if (Object.keys(selectedItem).length) {
// 										selectedItem.label = selectedItem.name;
// 										selectedItem.value = selectedItem.id;
// 										return selectedItem;
// 									} else {
// 										return {
// 											value: null,
// 											label: translationService.translate("TR_CHOOSE"),
// 										};
// 									}
// 								})()}
// 								items={documentType.map(data => ({
// 									...data,
// 									label: data.name,
// 									value: data.id,
// 								}))}
// 								onChange={item => onSelectOptionChange(item, "documentTypeId")}
// 							/>
// 						</div>
// 					</div>
// 					<div className="col-12 col-lg-4 col-md-6">
// 						<div className="form-group">
// 							<label htmlFor="">
// 								{translationService.translate("TR_DOCUMENT_TYPE")}
// 							</label>
// 							<ReactSelectOption
// 								value={documentTemplateId}
// 								isDisabled={documentId}
// 								selectedValue={(() => {
// 									const selectedItem = {
// 										...templates.find(data => data.id === documentTemplateId),
// 									};
// 									if (Object.keys(selectedItem).length) {
// 										selectedItem.label = selectedItem.name;
// 										selectedItem.value = selectedItem.id;
// 										return selectedItem;
// 									} else {
// 										return {
// 											value: null,
// 											label: translationService.translate("TR_CHOOSE"),
// 										};
// 									}
// 								})()}
// 								items={templates.map(data => ({
// 									...data,
// 									label: data.name,
// 									value: data.id,
// 								}))}
// 								onChange={item =>
// 									onSelectOptionChange(item, "documentTemplateId")
// 								}
// 							/>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="row">
// 					<div className="col-12 col-lg-4 col-md-6">
// 						<div className="course-media-wrapper">
// 							<p className="my-2">
// 								{translationService.translate("TR_UPLOAD_FILE")}
// 							</p>
// 							{uploadedFile ? (
// 								<div className="upload-file-content">
// 									<div onClick={removeImg}>
// 										<CloseSvg />
// 									</div>
// 									<div
// 										className="img"
// 										style={{ backgroundImage: `url(${uploadedFile})` }}
// 										alt="course-image"></div>
// 								</div>
// 							) : null}
// 							{uploadedFilePath && !file ? (
// 								<div className="upload-file-content">
// 									<div
// 										className="img"
// 										style={{ backgroundImage: `url(${uploadedFilePath})` }}
// 										alt="course-image"></div>
// 								</div>
// 							) : null}
// 						</div>
// 						<div className="course-media-wrapper">
// 							<div className="form-group position-relative">
// 								<UploadButton
// 									textInputClasses="pl-100"
// 									text={translationService.translate("TR_UPLOAD_FILE")}
// 									clicked={uploadFile}
// 								/>
// 							</div>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="row">
// 					<div className="col-12 col-lg-4 col-md-6">
// 						<Input
// 							type="text"
// 							id="name"
// 							name="name"
// 							inputClassName="pr--5"
// 							fieldLength={nameMaxLength}
// 							value={documentName}
// 							isInvalidSubmit={isInvalidSubmit}
// 							isInvalidField={isInvalidSubmit && !documentName.trim()}
// 							labelValue={`${translationService.translate(
// 								"TR_DOCUMENT_NAME",
// 							)} *`}
// 							onChange={event =>
// 								onChange(event, setDocumentName, nameMaxLength)
// 							}
// 						/>
// 					</div>
// 				</div>

// 				<div className="row">
// 					<div className="col-12">
// 						<Textarea
// 							rows="2"
// 							id="description"
// 							name="description"
// 							value={description}
// 							max={descriptionMaxLength}
// 							labelValue={`${translationService.translate(
// 								"TR_DESCRIPTION_OR_NUMBER",
// 							)}`}
// 							onChange={event => {
// 								if (event.target.value.length <= descriptionMaxLength) {
// 									setDescription(event.target.value);
// 								} else {
// 									return;
// 								}
// 							}}
// 						/>
// 					</div>
// 				</div>
// 				<div className="document-form">
// 					{/* {console.log(templateData.body)} */}
// 					<div
// 						ref={containerRef}
// 						dangerouslySetInnerHTML={{
// 							__html: template || "",
// 						}}
// 						className="template-container"
// 					/>
// 				</div>
// 			</div>
// 			<div className="row">
// 				<div className="col-12">
// 					<ActionButton
// 						type="button"
// 						spinnerId={buttonSpinnerId}
// 						className="mindalay--btn-dark mb-4"
// 						name={
// 							documentId
// 								? translationService.translate("TR_UPDATE")
// 								: translationService.translate("TR_CREATE")
// 						}
// 						clicked={onSubmit}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	) : null;
// };

// export default withRouter(DocumentForm);
